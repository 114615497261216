import React, {useEffect} from 'react';
import {AppBar, Box, TextField, Toolbar, Tooltip, Typography,InputAdornment} from "@mui/material";
import {NavLink, useNavigate} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import eventBus from "./EventBus";
import { useAuth } from '../../utils/auth';
import { useLocation } from 'react-router-dom';

import {
    AccountBox,
    Login,
    Logout,
    ThumbUpAlt,
    ViewList
} from '@mui/icons-material';

import IconButton from "@mui/material/IconButton";

const styles = {
    flex: {
        flex: 1,
    },
};

function NavBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const [searchQuery, setSearchQuery] = React.useState({value: localStorage.getItem("searchQuery") || ""}); // eslint-disable-line
    const [token, setToken] = React.useState({value: ""}) // eslint-disable-line

    const handleSearchFieldInput = (event) => {
        searchQuery.value = event.target.value;

        if (window.location.pathname.toString().includes("target_list")) {
            localStorage.setItem("searchQuery", searchQuery.value)
            localStorage.setItem("searchQueryPage", 1)
            eventBus.dispatch("searchChange", { value: searchQuery.value });
        }
    };

    const onKeyDownHandle = (event) => {
        if (event.key === 'Enter' && !window.location.pathname.toString().includes("target_list")) {
            localStorage.setItem("searchQuery", searchQuery.value)
            localStorage.setItem("searchQueryPage", 1)
            localStorage.setItem("searchQueryOrder", 'asc');
            localStorage.setItem("searchQueryOrderBy", 'name');
            localStorage.setItem("searchQueryName", "");
            localStorage.setItem("searchQueryAuthor", "");
            localStorage.setItem("searchQueryYear", "");
            navigate("/target_list");
        } 
    }

    const onLoopClickHandle = (event) => {
         if (!window.location.pathname.toString().includes("target_list")) {
             localStorage.setItem("searchQuery", searchQuery.value)
             localStorage.setItem("searchQueryPage", 1)
             localStorage.setItem("searchQueryOrder", 'asc');
             localStorage.setItem("searchQueryOrderBy", 'name');
             localStorage.setItem("searchQueryName", "");
             localStorage.setItem("searchQueryAuthor", "");
             localStorage.setItem("searchQueryYear", "");
             navigate("/target_list");
         }
     }

    useEffect(() => {
          let user = JSON.parse(localStorage.getItem("user"))
          if (user != null) {
             token.value = user.token
          }
          //console.log(token.value)
    }, []); // eslint-disable-line

    const handleLogout = () => {
        fetch(`${process.env.REACT_APP_API_ADDRESS}/logout`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token.value,
            }),
            // credentials: 'include',
        })
        .then((response) => {
            //console.log(response.status)
            auth.logout();
            navigate(0);
        })
    }

    return (

        <Box sx={styles.flex} id="menu">
            <AppBar position='sticky' disablegutters='true'>
                <Toolbar>
                    <Typography variant='title' color='inherit' sx={styles.flex}>
                        <NavLink to="/" color='inherit'> Molecules </NavLink>
                    </Typography>


                        <Tooltip title="Target List">
                            <IconButton size="large" color="inherit"
                            className={
                                location.pathname.includes("target")? "icon-button icon-button-active": "icon-button"
                                }
                            onClick = {() => { localStorage.setItem("searchQuery", "");
                                     localStorage.setItem("searchQueryPage", 1)
                                     localStorage.setItem("searchQueryOrder", 'asc');
                                     localStorage.setItem("searchQueryOrderBy", 'name');
                                     localStorage.setItem("searchQueryName", "");
                                     localStorage.setItem("searchQueryAuthor", "");
                                     localStorage.setItem("searchQueryYear", "");
                            }}
                            href="/target_list">
                                <ViewList/>
                            </IconButton>
                        </Tooltip>


                        <Tooltip title="Most Liked Reactions">
                            <IconButton size="large" color="inherit"
                               className={
                                   location.pathname.includes("most")? "icon-button icon-button-active": "icon-button"
                                   }
                                href="/most_liked">

                                             <ThumbUpAlt/>

                            </IconButton>
                         </Tooltip>

                    {auth.user &&
                    <Tooltip title="Your Account">
                        <IconButton size="large" color="inherit"
                          className={
                               location.pathname.includes("user")? "icon-button icon-button-active": "icon-button"
                               }
                         href="/user">

                                        <AccountBox/>

                        </IconButton>
                    </Tooltip>}
                    {auth.user &&
                    <Tooltip title="Sign out">
                        <IconButton size="large" color="inherit"
                          className="icon-button"
                          onClick={handleLogout}>

                                        <Logout/>

                        </IconButton>
                    </Tooltip>}
                    {!auth.user &&
                    <Tooltip title="Sign in / Sign up">
                        <IconButton size="large" color="inherit"
                          className="icon-button"
                          href="/login">
                             <Login/>
                         </IconButton>
                     </Tooltip>}

                     <div id="search_field_container"
                                             style={{
                                                 display: "flex",
                                                 alignSelf: "center",
                                                 justifyContent: "center",
                                                 flexDirection: "column",
                                                 maxWidth: '20%',
                                             }}
                                         >
                                          <Tooltip title="Search targets">
                                             <TextField
                                                 style={{
                                                     width: '100%',
                                                 }}
                                                 id="search-bar"
                                                 className="text"
                                                 onChange={handleSearchFieldInput}
                                                 onKeyDown={onKeyDownHandle}
                                                 hiddenLabel
                                                 variant="filled"
                                                 color={"primary"}
                                                 placeholder="Search"
                                                 size="medium"
                                                 defaultValue={searchQuery.value}
                                                 InputProps={{
                                                     disableUnderline: true,
                                                     endAdornment: (
                                                     <InputAdornment onClick={onLoopClickHandle}
                                                      sx={{
                                                         color:'black',
                                                         cursor:'pointer',
                                                        '&:hover ': {
                                                            color: "#03a8c0",
                                                         }
                                                      }}
                                                      position="start">

                                                            <SearchIcon fontSize="small"/>

                                                     </InputAdornment>
                                                     ),
                                                     sx: {
                                                         '&& input:-webkit-autofill': {
                                                             height: '8px',
                                                             borderRadius: '15px',
                                                             paddingLeft: '12px',
                                                             backgroundColor: 'white',
                                                         },
                                                         '&.MuiFilledInput-root': {
                                                             backgroundColor: 'white',
                                                             borderRadius: '15px',
                                                             height: '40px',
                                                             color: 'rgb(0 0 0)',
                                                             paddingRight: 0,
                                                         },
                                                         '.MuiFilledInput-input': {
                                                            paddingBottom: '16px !important',
                                                          },

                                                     },
                                                 }}
                                             />
                                             </Tooltip>
                                         </div>
                </Toolbar>
            </AppBar>
        </Box>


    );
}

export default NavBar;
