import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

//import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Box, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { useAuth } from '../utils/auth';

function Register() {
    const navigate = useNavigate();
//    const [firstName, setFirstName] = useState('');
//    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [registerErrorEmail, setRegisterErrorEmail] = useState('');
    const [registerErrorPassword, setRegisterErrorPassword] = useState('');
    const [loading, setLoading] = React.useState(false);
    const [registerSuccessInfo, setRegisterSuccessInfo] = useState('');
    const auth = useAuth();

    useEffect(() => {
            if(auth.user) {
                if(document.referrer.includes(process.env.REACT_APP_ADDRESS) ||
                                document.referrer.includes('localhost')) {
                                    navigate(-1, { replace: true });
                                    navigate(0);
                }
                else {
                    navigate('/user', { replace: true })
                }
            }
    }, []); // eslint-disable-line

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (e) => {
        const {id , value} = e.target;
//        if(id === "firstname"){
//            setFirstName(value);
//        }
//        if(id === "lastname"){
//            setLastName(value);
//        }
        if(id === "email"){
            setRegisterErrorEmail('');
            setEmail(value);
        }
        if(id === "password"){
            setRegisterErrorPassword('');
            setPassword(value);
        }
        if(id === "repeat-password"){
            setRegisterErrorPassword('');
            setConfirmPassword(value);
        }
    }

    const handleSubmit  = () => {
        if(email.length < 5 || !email.includes("@")) {
            let arr = ["ENTER A VALID EMAIL ADDRESS"];
            setRegisterErrorEmail(arr);
        }
        else if (password.length < 8 && password === confirmPassword) {
            let arr = ["Password too short"];
            setRegisterErrorPassword(arr);
        }
        else if (password !== confirmPassword) {
            let arr = ["Passwords are different"];
            setRegisterErrorPassword(arr);
        }
        else
           signUp();

    }

    const signUp = () => {
        setRegisterErrorEmail('');
        setRegisterErrorPassword('');
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_ADDRESS}/register/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
		        password2: password
            }),
        })
        .then(response => {
            setLoading(false);
            let data;
            if (response.ok) {
                setRegisterSuccessInfo('The verification email has been sent.')
                setTimeout(() => {
                  navigate("/login", { replace: true })
                }, "2000");
//                data = {email : [], password: []}
                // console.log(response);
            } else {
                data = response.json();
                // console.log(response);
            }
            return data;
        })
        .then(data => {
//            data.email && setRegisterErrorEmail(`Email — ${data.email}`)
//            data.password && setRegisterErrorPassword(`Password — ${data.password}`)
            if(data) {
                data.email && setRegisterErrorEmail(data.email)
                data.password && setRegisterErrorPassword(data.password)
            }
        });
    }
        // <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
        //                    <PersonIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
        //                    <TextField
        //                        required
        //                        fullWidth
        //                        id="firstname"
        //                        label="Your first name"
        //                        size="small"
        //                        value={firstName}
        //                        InputLabelProps={{ shrink: true }}
        //                        onChange = {(e) => handleInputChange(e)}
        //                    />
        //                </Box>
        //                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
        //                    <PersonIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
        //                    <TextField
        //                        required
        //                        fullWidth
        //                        id="lastname"
        //                        label="Your last name"
        //                        size="small"
        //                        value={lastName}
        //                        InputLabelProps={{ shrink: true }}
        //                        onChange = {(e) => handleInputChange(e)}
        //                    />
        //                </Box>
    return (
        <Box
            component="form"
            sx={{
                width: '40%',
                textAlign: 'center',
                margin: '30px auto',
                border: '0.2px solid grey',
                padding: '30px',
                borderRadius: '15px',
                boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="on"
            >
                <h1 className="text-uppercase text-center mb-5" style={{marginTop:'0px'}}>Sign up</h1>
                <Box sx={{marginTop: '28px', marginBottom: '28px'}}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                        <EmailIcon sx={{ color: !registerErrorEmail ? 'action.active' :'red', mr: 0.2, my: 2.1 }} />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            required
                            fullWidth
                            id="email"
                            label="Your email"
                            size="small"
                            value={email}
                            autoComplete="email"
                            onKeyDown={(e) => {if(e.keyCode === 13) handleSubmit()}}
                            onChange = {(e) => handleInputChange(e)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                        <LockIcon sx={{ color: !registerErrorPassword ? 'action.active' : 'red', mr: 0.2, my: 2.1 }} />
                        <TextField
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            size="small"
                            value={password}
                            autoComplete="off"
                            onKeyDown={(e) => {if(e.keyCode === 13) handleSubmit()}}
                            onChange = {(e) => handleInputChange(e)}
                            type={showPassword ? 'text' : 'password'}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                        <KeyIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
                        <TextField
                            required
                            fullWidth
                            id="repeat-password"
                            label="Repeat password"
                            size="small"
                            value={confirmPassword}
                            autoComplete="off"
                            onKeyDown={(e) => {if(e.keyCode === 13) handleSubmit()}}
                            onChange = {(e) => handleInputChange(e)}
                            type={showRepeatPassword ? 'text' : 'password'}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle repeat password visibility"
                                            onClick={handleClickShowRepeatPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                    <Box sx={{marginTop: '20px'}}>
                        {registerErrorEmail && (registerErrorEmail.map(function(data, id) {
                                                      return (
                                                        <p key={`ree${id}`} style={{color: 'red', fontSize: '0.9em',textTransform: 'uppercase', marginTop:5, marginBottom: 0}}>
                                                         {data}
                                                        </p>
                                                      )
                                                  })
                                               )}

                        {registerErrorPassword && (registerErrorPassword.map(function(data, id) {
                                                      return (
                                                        <p key={`rep${id}`} style={{color: 'red', fontSize: '0.9em', textTransform: 'uppercase', marginTop:5, marginBottom: 0}}>
                                                         {data}
                                                        </p>
                                                      )
                                                  })
                                               )}
                        {registerSuccessInfo && (
                                                    <p key={1} style={{color: '#03a8c0', fontSize: '0.9em', textTransform: 'uppercase', marginTop:5, marginBottom: 0}}>
                                                     {registerSuccessInfo}
                                                    </p>
                                                  )
                                              }
                    </Box>
                </Box>
                <LoadingButton
                    size="medium"
                    disabled={registerSuccessInfo.length > 0 ? true : false}
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    sx={{marginBottom: '19px', width: '50%', '&:hover':{color:'#03a8c0'}}}
                    >
                    <span>Sign up</span>
                </LoadingButton>

                <h2 style={{fontSize: '16px'}}>Already have an account? <a href='/login' style={{textDecoration: 'underline'}}>Sign in here!</a></h2>
        </Box>
    );
}

export default Register;

//{registerErrorEmail && (<p className="error" style={{ textTransform: 'uppercase', marginBottom: 0, fontWeight:'bold'}}> EMAIL </p>)}
//{registerErrorPassword && (<p className="error" style={{textTransform: 'uppercase', marginBottom: 0, fontWeight:'bold' }}> PASSWORD </p>)}