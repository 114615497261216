import React, {useEffect, useState} from 'react';
import styles from '../assets/css/reaction.module.css';
import $ from "jquery";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Button, Slider} from "@mui/material";
import { debounce } from 'lodash';
import { useAuth } from "../utils/auth";

import {
//    Error,
//    ErrorOutline,
    Favorite,
    FavoriteBorder,
    ThumbDownAlt,
    ThumbDownOffAlt,
    ThumbUpAlt,
    ThumbUpOffAlt
} from '@mui/icons-material';
import {useParams} from 'react-router';
import IconButton from "@mui/material/IconButton";
//import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {styled} from '@mui/material/styles';
//import TextField from '@mui/material/TextField';

//const CustomTooltip = styled(({className, ...props}) => (
//    <Tooltip {...props} classes={{popper: className}}/>
//))(({theme}) => ({
//    [`& .${tooltipClasses.tooltip}`]: {
//        backgroundColor: theme.palette.common.white,
//        padding: '10px',
//        color: 'rgba(0, 0, 0, 0.87)',
//        boxShadow: theme.shadows[1],
//    },
//}));


function debounceCustom(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

function downloadMoleculeSVGAsImage(data, fileName, fileType = "svg") {
    let svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'})
    let url = URL.createObjectURL(svgBlob)

    let a = document.createElement('a')
    a.setAttribute('download', fileName + '.' + fileType)
    a.setAttribute('href', url)
    a.setAttribute('target', '_blank')
    a.click()
}

const get3DCoordinates = async (smile) => {
    const response =
            await fetch(`${process.env.REACT_APP_API_ADDRESS}/3d`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        smiles: smile,
                    }),
                    // credentials: 'include',
                })
        const coordinates = await response.json();
        return coordinates.coordinates;
    }

function drawVisualization(smile, svg) {
    let size = $("#mol3D").width();

    let loaderContainer = $('<div>').attr('id', 'loader_3D').css({
        backgroundColor: 'transparent',
        width: size, height: size,
        zIndex: '10010',
        position: 'absolute',
        top: '0', left: '0',
    })

    let loader = $('<div>').css({
        position: 'absolute',
        top: size / 2 - 58, left: size / 2 - 58,
    }).addClass('loader');

    loaderContainer.append(loader);

    let loaderContainer1 = loaderContainer.clone();
    loaderContainer1.attr('id', 'loader_3D_1')

    $("#mol2D").append(loaderContainer);
    $("#mol3D").append(loaderContainer1);


    $("#mol2D").empty();
    $("#mol2D").css({
        background: 'RGBA(0,0,0,.5) url(data:image/svg+xml;base64,' + svg + ') no-repeat center',
        backgroundSize: 'contain',
    })

    get3DCoordinates(smile).then(coordinates => {
            //console.log(coordinates)
            let config = {};
            $("#mol3D").empty();
            let viewer = $3Dmol.createViewer($("#mol3D"), config); // eslint-disable-line
            //console.log(mol)
            let rec1 = viewer.addModel(coordinates, "sdf");
            //rec1.setStyle({stick:{opacity:'1.0'}, sphere:{radius: 0.4}});
            rec1.setStyle({stick:{radius: 0.1}, sphere:{radius: 0.3}});
            viewer.center();
            viewer.zoomTo();
            viewer.spin();
            //viewer.fitSlab();
            viewer.render();
            $("#mol3D").mousedown(function (e) {
                viewer.spin(false);
            })
    });

}


const drawStage = debounce((reaction, auth) => {
//    var startTime = performance.now()

    let maxStageNumber = reaction.maxStage;
    let currentStageNumber = reaction.focused;
    let name = reaction.name;

    let containerDim = $("#reaction_content").innerWidth();
    let molDim = Math.ceil($("#reaction_content").innerWidth() * 0.33);
    let stageContainer = $("#reaction_container_main");
    //stageContainer.height(molDim);
    //stageContainer.empty();
    if (reaction?.stages?.length > 0) {
        let subSmileText = reaction.stages[currentStageNumber].substrates;
        let prodSmileText = reaction.stages[currentStageNumber].product;
        let catalystLigand = reaction.stages[currentStageNumber].catalyst_ligand;

        let condText = reaction.stages[currentStageNumber].conditions;
        let reactionName = reaction.stages[currentStageNumber].rx_name;
        condText = condText.replaceAll('.', ', ')

        let condSmilesTexts = [];
        let subReactionsSteps = []
        let subReactionForMain = -1;
        let subReactionForMainSteps = -1;
        let subReactionsForElse = [];
        let subReactionsForElseSteps = [];

        let prevProd = ""; // produkt poprzedniego kroku
        if(currentStageNumber > 0)
            prevProd = reaction.stages[currentStageNumber - 1].product

        let subReactionsProds = []; // produkty subreakcji
        reaction.stages[currentStageNumber].sub?.forEach((e) => {
            subReactionsProds.push(e.build[e.build.length - 1].product)
            subReactionsSteps.push(e.build.length)
        })

        let subReactionsProdsRaw = [...subReactionsProds]; // kopia produktów subreakcji

        let smiles = [];
        let smilesSubs = [];
        let smilesCatLig = [];

        if(prevProd !== '') // jeśli był poprzedni krok
            if(subSmileText.includes(prevProd)){ // sprawdź, czy w substratach jest produkt porzedniego
                subSmileText = subSmileText.replace(prevProd, '') // usuń z substratów produkt poprzedniego
                subSmileText = subSmileText.replace('..', '.') // czyszczenie
                if(subSmileText.startsWith('.'))
                    subSmileText = subSmileText.substring(1);
                smiles.push(prevProd) // zachowaj substrat
            }

        if(subSmileText !== '') // jeśli są substraty
            subReactionsProds.forEach((e, i) => { // przejdź po produkrach subreakcji
                if(subSmileText.includes(e)) { // szukaj produktu subreakcji zgodnego z substratem
                   subSmileText = subSmileText.replace(e, ''); // usuń z substratów produkt subreakcji
                   subSmileText = subSmileText.replace('..', '.') // czyszczenie
                   if(subSmileText.startsWith('.'))
                      subSmileText = subSmileText.substring(1);
                   smilesSubs.push(e); // zachowaj
                   subReactionsProds.splice(i, 1); // usun znaleziony, żeby nie powielać
                }
            })

        let smilesCatLigText = catalystLigand !== "NULL" ? catalystLigand : ""

        if(smilesCatLigText !== '') { // jeśli są cat/lig
            subReactionsProds.forEach((e, i) => { // przejdź po produkrach subreakcji
                if(smilesCatLigText.includes(e)) { // szukaj produktu subreakcji zgodnego z cat/lig
                   smilesCatLigText = smilesCatLigText.replace(e, ''); // usuń z substratów cat/lig
                   smilesCatLigText = smilesCatLigText.replace('..', '.') // czyszczenie
                   if(smilesCatLigText.startsWith('.'))
                      smilesCatLigText = smilesCatLigText.substring(1);
                   smilesCatLig.push(e); // zachowaj
                   subReactionsProds.splice(i, 1); // usun znaleziony, żeby nie powielać
                }
            })
            if(smilesCatLigText !== "") // jesli cos zostalo
                smilesCatLig = [...smilesCatLig, ...smilesCatLigText.split('.')] // zachowaj pozostale z podzialem po .
        }

        smiles = [...smiles, ...smilesSubs] // polacz znalezione
        if(subSmileText !== "")
            smiles = [...smiles, ...subSmileText.split('.')] // zachowaj pozostale z podzialem po .

        smiles.splice(1, 0, ...smilesCatLig); // wstaw cat/lig jako drugi+ element do wyswietlenia

        smiles.forEach((s,j) => { // łączenie subreakcji z wyświetlanymi elementami
            subReactionsProdsRaw.forEach((e, i) => {
               if(e === s) {
                    if(j === 0) {
                        subReactionForMain = i;
                        subReactionForMainSteps = subReactionsSteps[i]
                    }
                    else {
                        subReactionsForElse.push(i)
                        subReactionsForElseSteps.push(subReactionsSteps[i])
                    }
                 }
                 else {
                     if(j === 0) {
                         subReactionForMain = -1;
                     }
                     else {
                         subReactionsForElse.push(-1)
                     }
                 }
               })
        })

        subSmileText = smiles[0]; // pierwszy smiles na lewo
        smiles.splice(0, 1); // pozostale na srodek


//        console.log('###################')
//        console.log(prevProd)
//        console.log(smiles)
//        console.log(subSmileText)
//        console.log('###################')

//        const smiles2 = reaction.stages[currentStageNumber].substrates.split('.');
//        // const smilesCatLig = catalystLigand !== "NULL"? catalystLigand: [];
//        const smilesCatLig2 = reaction.stages[currentStageNumber].catalyst_ligand !== "NULL" ? catalystLigand.split('.') : [];
//
//        if (smilesCatLig2.length > 0)
//            smiles2.splice(1, 0, ...smilesCatLig2); // smiles.splice(1, 0, ...smilesCatLig);
//        let lastProduct = "";
//        if (currentStageNumber - 1 >= 0) {
//            lastProduct = reaction.stages[currentStageNumber - 1].product
//        }
//        for (let i = 0; i < smiles2.length; i++) {
//            if (smiles2[i] === lastProduct) {
//                 let element = smiles2[i];
//                 smiles2.splice(i, 1);
//                 smiles2.unshift(element);
//                 break;
//            }
//        }
//        let subSmileText2 = smiles[0];
//        smiles2.splice(0, 1);

        condSmilesTexts = smiles;

        let smilesAll = [subSmileText, ...condSmilesTexts, prodSmileText];

        Promise.all(smilesAll.map((smile) =>
                fetch(`${process.env.REACT_APP_API_ADDRESS}/single_svg`, {
                   method: "POST",
                   headers: {
                       "Content-Type": "application/json",
                   },
                   body: JSON.stringify({
                       smile: smile,
                   }),
               // credentials: 'include',
            })
            .then((response) => response.json())))
            .then((jsons) => {
                let subContainer = document.createElement("div");
                subContainer.id = "substrate_container_" + currentStageNumber;
                subContainer.style.width = molDim + "px";
                subContainer.style.height = molDim + "px";
                subContainer.style.position = "relative";
                subContainer.style.display = "inline-block"
                subContainer.style.float = "left"
                subContainer.style.order = "1"
                if (currentStageNumber === 0) {
                    subContainer.style.borderLeft = "1px solid lightgrey";
                }
//                mdetails['width'] = molDim;
//                mdetails['height'] = molDim;
//                console.log(subReactionForMain)
                if (subReactionForMain !== -1) {
                    subContainer.style.borderLeft = "1px dashed lightgrey";
                    subContainer.style.borderRight = "1px dashed lightgrey";
                    let infoContainer = document.createElement("div");
                    infoContainer.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
                    infoContainer.style.fontStyle = "italic";
                    infoContainer.style.position = "absolute"
                    infoContainer.style.color = "#03a8c0"
                    infoContainer.style.fontWeight = "bold"
                    infoContainer.style.fontSize = "0.9em"
                    infoContainer.style.lineHeight = "0.9em"
                    infoContainer.style.right = "1px"
                    infoContainer.style.bottom = "1px"
                    infoContainer.innerHTML = "+" + subReactionForMainSteps;
                    subContainer.append(infoContainer)
                }

                let parser = new DOMParser();
                let subSVGRaw = jsons[0].svg;
                let xmlDoc = parser.parseFromString(subSVGRaw, 'text/xml');
                xmlDoc.firstChild.setAttribute("height", molDim);
                xmlDoc.firstChild.setAttribute("width", molDim);

                let subSmileMolSvg = xmlDoc.firstChild
                subContainer.append(subSmileMolSvg);

                subContainer.style.cursor = "zoom-in"

                subContainer.onclick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let fileName2 = name + "_substrate(stage" + (currentStageNumber + 1) + ")"
                    let mySVG64sub = window.btoa(subSmileMolSvg.outerHTML);
//                    console.log(subSmileMolSvg.outerHTML)
                    $('<div>').css({
                        background: 'RGBA(0,0,0,.5) url(data:image/svg+xml;base64,' + mySVG64sub + ') no-repeat center',
                        backgroundSize: 'contain',
                        width: '100%', height: '100%',
                        position: 'fixed',
                        zIndex: '10000',
                        top: '0', left: '0',
                        cursor: 'zoom-out'
                    }).click(function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        $(this).remove();
                    }).appendTo('body')
                        .append($('<div>').addClass('molecule_board')
                        .append($('<i>')
                            .addClass('fa fa-cube fa-2x molecule_board_icon molecule_board_icon_left')
                            .click(function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                                let flexT = "column";
                                let size = window.innerHeight / 2;
                                if (window.innerWidth > window.innerHeight) {
                                    size = window.innerWidth / 2;
                                    flexT = "row";
                                }
                                $('<div>').attr('id', 'mol3D_container').css({
                                    backgroundColor: 'RGBA(0,0,0,.5)',
                                    width: '100%', height: '100%',
                                    position: 'fixed',
                                    zIndex: '10002',
                                    top: '0', left: '0',
                                    display: 'flex',
                                    flexDirection: flexT,
                                    alignItems: 'center',
                                    verticalAlign: 'middle'
                                })
                                    .append($('<div>').attr('id', 'mol3D').css({
                                        backgroundColor: 'white',
                                        width: size, height: size,
                                        zIndex: '10003',
                                        position: 'relative',
                                    }))
                                    .append($('<div>').attr('id', 'mol2D').css({
                                        backgroundColor: 'white',
                                        width: size, height: size,
                                        zIndex: '10004',
                                        position: 'relative',
                                    }))
                                    .append($('<div>').addClass('molecule_board_3D')
                                    .append($('<i>')
                                        .addClass('fa-solid fa-xmark fa-2x molecule_board_icon')
                                        .click(function () {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            $("#mol3D").first().width(0).height(0);
                                            //viewer.clear();
                                            $("#mol3D").first().remove();
                                            $("#mol3D_container").remove();
                                        }))
                                    ).appendTo('body')
                                drawVisualization(subSmileText, mySVG64sub);
                            }))
                            .append(function() { if(auth) return $('<i>')
                                .addClass('fa-solid fa-save fa-2x molecule_board_icon')
                                .click(function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    downloadMoleculeSVGAsImage(subSVGRaw, fileName2); // subSmileMolSvgSave
                            })})
                        )
                }

                let condContainer = document.createElement("div");
                condContainer.id = "condition_container_" + currentStageNumber;
                condContainer.style.width = molDim + "px";
                condContainer.style.height = molDim + "px";
                condContainer.style.float = "left"
                condContainer.style.position = "relative";
                condContainer.style.display = "flex"
                condContainer.style.flexDirection = "column"
                condContainer.style.alignItems = "center"
                condContainer.style.overflow = "visible";
                condContainer.style.order = "2"


                let condContainerUpperPartHeight = molDim * 0.47;
                let condContainerUpperPart = document.createElement("div");
                condContainerUpperPart.style.width = molDim + "px";
                condContainerUpperPart.style.height = condContainerUpperPartHeight + "px";
                condContainerUpperPart.style.display = "inline-block"
                condContainerUpperPart.style.margin = "0"
                condContainerUpperPart.style.padding = "0"
                condContainerUpperPart.style.display = "flex"
                condContainerUpperPart.style.flexDirection = "column"
                condContainerUpperPart.style.alignItems = "center"
                condContainerUpperPart.style.overflow = "visible";

                let condContainerLowerPartHeight = molDim * 0.47;
                let condContainerLowerPart = document.createElement("div");
                condContainerLowerPart.style.width = molDim + "px";
                condContainerLowerPart.style.height = condContainerLowerPartHeight + "px";
                condContainerLowerPart.style.margin = "0"
                condContainerLowerPart.style.padding = "0"
                condContainerLowerPart.style.display = "flex"
                condContainerLowerPart.style.flexDirection = "column"
                condContainerLowerPart.style.alignItems = "center"
                condContainerLowerPart.style.overflow = "visible";

                let condSmileContainer = document.createElement("div");
                condSmileContainer.id = "condition_smile_container_" + currentStageNumber;
                condSmileContainer.style.margin = "0 auto";
                condSmileContainer.style.padding = "0"
                condSmileContainer.style.width = molDim + "px";
                condSmileContainer.style.float = "left"
                condSmileContainer.style.display = "flex"
                condSmileContainer.style.flexDirection = "column"
                condSmileContainer.style.alignItems = "center"


                if (condSmilesTexts.length > 0) {
                    for (let i = 0; i < jsons.slice(1, jsons.length).length - 1; i++) {
                        let condSmileContainerSub = document.createElement("div");
                        condSmileContainerSub.id = "condition_smile_container_" + currentStageNumber + "_sub_" + i;
                        condSmileContainerSub.style.display = "flex"
                        condSmileContainerSub.style.position = "relative"
//                        mdetails['width'] = Math.round(condContainerUpperPartHeight * 0.7);
//                        mdetails['height'] = Math.round(condContainerUpperPartHeight * 0.7);
//                        console.log(subReactionForElse[i])
//                        console.log(subReactionForElse)
                        if(subReactionsForElse[i] !== undefined) {
                            if (subReactionsForElse[i] !== -1) {
                                condSmileContainerSub.style.border = "1px dashed lightgrey";
                                let infoContainer = document.createElement("div");
                                infoContainer.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
                                infoContainer.style.fontStyle = "italic";
                                infoContainer.style.position = "absolute"
                                infoContainer.style.color = "#03a8c0"
                                infoContainer.style.fontWeight = "bold"
                                infoContainer.style.fontSize = "0.9em"
                                infoContainer.style.lineHeight = "0.9em"
                                infoContainer.style.right = "1px"
                                infoContainer.style.bottom = "1px"
                                infoContainer.innerHTML = "+" + subReactionsForElseSteps[i];
                                condSmileContainerSub.append(infoContainer)
                            }
                        }

                        let condSVGRaw = jsons[i + 1].svg;
                        xmlDoc = parser.parseFromString(condSVGRaw, 'text/xml');
                        xmlDoc.firstChild.setAttribute("height", Math.round(condContainerUpperPartHeight * 0.7));
                        xmlDoc.firstChild.setAttribute("width", Math.round(condContainerUpperPartHeight * 0.7));

                        let condSmileMolSvg = xmlDoc.firstChild
                        condSmileContainerSub.append(condSmileMolSvg);

                        condSmileContainerSub.style.cursor = "zoom-in";

                        condSmileContainer.append(condSmileContainerSub)

                        condSmileContainerSub.onclick = (e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            let fileName2 = name + "_element" + (i + 2) + "(stage" + (currentStageNumber + 1) + ")"
                            let mySVG64cond = window.btoa(condSmileMolSvg.outerHTML);// condSmileMolSvgSave
                            $('<div>').css({
                                background: 'RGBA(0,0,0,.5) url(data:image/svg+xml;base64,' + mySVG64cond + ') no-repeat center',
                                backgroundSize: 'contain',
                                width: '100%', height: '100%',
                                position: 'fixed',
                                zIndex: '10000',
                                top: '0', left: '0',
                                cursor: 'zoom-out'
                            }).click(function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                                $(this).remove();
                            }).appendTo('body')
                                .append($('<div>').addClass('molecule_board')
                                .append($('<i>')
                                    .addClass('fa fa-cube fa-2x molecule_board_icon molecule_board_icon_left')
                                    .click(function (e) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        let flexT = "column";
                                        let size = window.innerHeight / 2;
                                        if (window.innerWidth > window.innerHeight) {
                                            size = window.innerWidth / 2;
                                            flexT = "row";
                                        }
                                        $('<div>').attr('id', 'mol3D_container').css({
                                            backgroundColor: 'RGBA(0,0,0,.5)',
                                            width: '100%', height: '100%',
                                            position: 'fixed',
                                            zIndex: '10002',
                                            top: '0', left: '0',
                                            display: 'flex',
                                            flexDirection: flexT,
                                            alignItems: 'center',
                                        })
                                            .append($('<div>').attr('id', 'mol3D').css({
                                                backgroundColor: 'white',
                                                width: size, height: size,
                                                zIndex: '10003',
                                                position: 'relative',
                                            }))
                                            .append($('<div>').attr('id', 'mol2D').css({
                                                backgroundColor: 'white',
                                                width: size, height: size,
                                                zIndex: '10004',
                                                position: 'relative',
                                            }))
                                            .append($('<div>').addClass('molecule_board_3D')
                                            .append($('<i>')
                                                .addClass('fa-solid fa-xmark fa-2x molecule_board_icon')
                                                .click(function () {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    $("#mol3D").first().width(0).height(0);
                                                    //viewer.clear();
                                                    $("#mol3D").first().remove();
                                                    $("#mol3D_container").remove();
                                                }))
                                            ).appendTo('body')
                                        drawVisualization(condSmilesTexts[i], mySVG64cond);
                                    }))
                                    .append(function() { if(auth) return $('<i>')
                                        .addClass('fa-solid fa-save fa-2x molecule_board_icon')
                                        .click(function (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            downloadMoleculeSVGAsImage(condSVGRaw, fileName2);
                                    })})
                                )
                        }

                    }
                } else {
                    condSmileContainer.style.height = "0";
                    condSmileContainer.style.display = "flex"
                }

                let condTextContainer = document.createElement("div");
                condTextContainer.id = "condition_text_container_" + currentStageNumber;
                condTextContainer.style.width = molDim * 0.6 + "px";
                condTextContainer.style.display = "table";
                condTextContainer.style.margin = "0 auto";
                condTextContainer.style.padding = "0"


                let condTextContainerInner = document.createElement("div");
                condTextContainerInner.id = "condition_text_container_inner_" + currentStageNumber;
                condTextContainerInner.style.width = molDim * 0.6 + "px";
                condTextContainerInner.style.display = "table-cell";
                condTextContainerInner.style.verticalAlign = "bottom";
                condTextContainerInner.style.margin = "0";
                condTextContainerInner.style.padding = "0"
                let conditionText = prepareCondition(condText)

                condTextContainerInner.innerHTML = conditionText
                condTextContainer.append(condTextContainerInner)

                let condArrowContainer = document.createElement("div");
                condArrowContainer.id = "condition_arrow_container_" + currentStageNumber;
                condArrowContainer.style.width = molDim + "px";
                condArrowContainer.style.height = molDim * 0.07 + "px";
                condArrowContainer.style.margin = "0";
                condArrowContainer.style.padding = "0"

                let arrow = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' + molDim + ' ' + molDim * 0.07 + '">' +
                    '  <defs>' +
                    '    <marker id="arrowhead" markerWidth="' + containerDim * 0.01 + '" markerHeight="' + containerDim * 0.01 + '"' +
                    '    refX="' + containerDim * 0.005 + '" refY="' + containerDim * 0.005 + '" orient="auto">' +
                    '      <polygon points="0 0, ' + containerDim * 0.01 + ' ' + containerDim * 0.005 + ', 0 ' + containerDim * 0.01 + '" />' +
                    '    </marker>' +
                    '  </defs>' +
                    '  <line x1="' + molDim * 0.2 + '" ' +
                    '        y1="' + containerDim * 0.013 + '" ' +
                    '        x2="' + molDim * 0.8 + '" ' +
                    '        y2="' + containerDim * 0.013 + '" stroke="#000"' +
                    '  stroke-width="1" marker-end="url(#arrowhead)" />' +
                    '</svg>'
                condArrowContainer.innerHTML = arrow;

                let condReactionNameContainer = document.createElement("div");
                condReactionNameContainer.id = "condition_reaction_name_container_" + currentStageNumber;
                condReactionNameContainer.style.width = molDim * 0.6 + "px";
                condReactionNameContainer.style.display = "table";
                condReactionNameContainer.style.margin = "0 auto";
                condReactionNameContainer.style.padding = "0"

                let condReactionNameSpan = document.createElement("span");
                condReactionNameSpan.id = "condition_reaction_name_span_" + currentStageNumber;
                condReactionNameSpan.style.width = molDim * 0.6 + "px";
                condReactionNameSpan.style.display = "table-cell";
                condReactionNameSpan.style.verticalAlign = "top";
                condReactionNameSpan.style.margin = "0 auto";
                condReactionNameSpan.style.fontWeight = "bold"
                condReactionNameSpan.style.fontStyle = "italic"

                condReactionNameSpan.innerText = reactionName;
                condReactionNameContainer.append(condReactionNameSpan)

                let condEmptyContainer = document.createElement("div");
                condEmptyContainer.style.width = molDim + "px";
                condEmptyContainer.style.display = "flex";

                let condEmptyContainer2 = document.createElement("div");
                condEmptyContainer2.style.width = molDim + "px";
                condEmptyContainer2.style.display = "flex";

                condContainerUpperPart.append(condEmptyContainer);
                condContainerUpperPart.append(condSmileContainer);
                condContainerUpperPart.append(condTextContainer);


                condContainerLowerPart.append(condReactionNameContainer);
                condContainerLowerPart.append(condEmptyContainer2);

                condContainer.append(condContainerUpperPart);
                condContainer.append(condArrowContainer);
                condContainer.append(condContainerLowerPart);

                stageContainer.append(condContainer);

                let marginHeightUpperPart = condContainerUpperPartHeight - condSmileContainer.getBoundingClientRect().height
                    - condTextContainer.getBoundingClientRect().height;
                let marginHeightLowerPart = condContainerLowerPartHeight - condReactionNameContainer.getBoundingClientRect().height;
                condEmptyContainer.style.height = marginHeightUpperPart + "px";
                condEmptyContainer2.style.height = marginHeightLowerPart + "px";

                let condContainerUpperPartHeightFin =
                    condSmileContainer.getBoundingClientRect().height +
                    condTextContainer.getBoundingClientRect().height + condEmptyContainer.getBoundingClientRect().height;
//                console.log(condContainerUpperPartHeightFin, condContainerUpperPartHeight)
                if (condContainerUpperPartHeightFin >= condContainerUpperPartHeight) {
                    let condInfoButton = document.createElement("button");
                    condInfoButton.id = "condition_info_button_" + currentStageNumber;
                    $('<i>').addClass('fas fa-info-circle fa-2x info_icon').appendTo(condInfoButton);
                    // condInfoButton.append(//.append($('<i>').addClass('fas fa-info-circle fa-2x info_icon'))
                    condInfoButton.style.bottom = "0";
                    condInfoButton.style.backgroundColor = "white";
                    condInfoButton.style.border = "none";
                    condInfoButton.style.cursor = "pointer";
                    condInfoButton.onclick = () => {
                        $('<div>', {id: 'condFull'})
                        .append(condSmileContainer)
                        .append(condTextContainer.cloneNode(true))
                        .append(condArrowContainer.cloneNode(true))
                        .append(condReactionNameContainer.cloneNode(true))
                        .css({
                            backgroundColor: 'rgba(255,255,255,1)',
                            boxShadow: "5px 0px 10px 0px rgba(0, 0, 0, 0.1),"
                             + "-5px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                            width: molDim, height: molDim,
                            position: 'absolute',
                            zIndex: '10000',
                            top: '0', left: '0',
                            textAlign: 'center',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }).appendTo(condContainer);
                         condContainer.addEventListener("mouseleave", function listener() {
                            //condContainer.removeChild(condContainer.lastChild)
                            document.getElementById("condFull").remove();
                            condContainer.removeEventListener('mouseleave', listener);
                         });
                    }


                    condContainerUpperPart.innerHTML = "";
                    condContainerUpperPart.append(condEmptyContainer);
                    condContainerUpperPart.append(condInfoButton);

                    marginHeightUpperPart = condContainerUpperPartHeight - condInfoButton.getBoundingClientRect().height;
                    condEmptyContainer.style.height = marginHeightUpperPart + "px";

                }

                let prodContainer = document.createElement("div");
                prodContainer.id = "product_container_" + currentStageNumber;
                prodContainer.style.width = molDim + "px";
                prodContainer.style.height = molDim + "px";
                prodContainer.style.display = "inline-block"
                prodContainer.style.float = "left"
                prodContainer.style.order = "3"
                if (currentStageNumber === maxStageNumber - 1) {
                    prodContainer.style.borderLeft = "1px solid lightgrey";
                    stageContainer.css('borderRight', "1px solid lightgrey");
                }
//                mdetails['width'] = molDim;
//                mdetails['height'] = molDim;

                let prodSVGRaw = jsons[jsons.length - 1].svg;
                xmlDoc = parser.parseFromString(prodSVGRaw, 'text/xml');
                xmlDoc.firstChild.setAttribute("height", molDim);
                xmlDoc.firstChild.setAttribute("width", molDim);

                let prodSmileMolSvg = xmlDoc.firstChild
                prodContainer.append(prodSmileMolSvg);

                prodContainer.style.cursor = "zoom-in"

                prodContainer.onclick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let fileName2 = name + "_product(stage" + (currentStageNumber + 1) + ")";
                    if (currentStageNumber === maxStageNumber - 1)
                        fileName2 = name;
                    let mySVG64prod = window.btoa(prodSmileMolSvg.outerHTML); // prodSmileMolSvgSave
                    $('<div>').css({
                        background: 'RGBA(0,0,0,.5) url(data:image/svg+xml;base64,' + mySVG64prod + ') no-repeat center',
                        backgroundSize: 'contain',
                        width: '100%', height: '100%',
                        position: 'fixed',
                        zIndex: '10000',
                        top: '0', left: '0',
                        cursor: 'zoom-out'
                    }).click(function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        $(this).remove();
                    }).appendTo('body')
                        .append($('<div>').addClass('molecule_board')
                        .append($('<i>')
                            .addClass('fa fa-cube fa-2x molecule_board_icon molecule_board_icon_left')
                            .click(function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                                let flexT = "column";
                                let size = window.innerHeight / 2;
                                if (window.innerWidth > window.innerHeight) {
                                    size = window.innerWidth / 2;
                                    flexT = "row";
                                }
                                $('<div>').attr('id', 'mol3D_container').css({
                                    backgroundColor: 'RGBA(0,0,0,.5)',
                                    width: '100%', height: '100%',
                                    position: 'fixed',
                                    zIndex: '10002',
                                    top: '0', left: '0',
                                    display: 'flex',
                                    flexDirection: flexT,
                                    alignItems: 'center',
                                    verticalAlign: 'middle'
                                })
                                    .append($('<div>').attr('id', 'mol3D').css({
                                        backgroundColor: 'white',
                                        width: size, height: size,
                                        zIndex: '10003',
                                        position: 'relative',
                                    }))
                                    .append($('<div>').attr('id', 'mol2D').css({
                                        backgroundColor: 'white',
                                        width: size, height: size,
                                        zIndex: '10004',
                                        position: 'relative',
                                    }))
                                    .append($('<div>').addClass('molecule_board_3D')
                                    .append($('<i>')
                                        .addClass('fa-solid fa-xmark fa-2x molecule_board_icon')
                                        .click(function () {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            $("#mol3D").first().width(0).height(0);
                                            //viewer.clear();
                                            $("#mol3D").first().remove();
                                            $("#mol3D_container").remove();
                                        }))
                                    )
                                    .appendTo('body')
                                drawVisualization(prodSmileText, mySVG64prod);
                            }))
                            .append(function() { if(auth) return $('<i>').addClass('fa-solid fa-save fa-2x molecule_board_icon')
                                .click(function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    downloadMoleculeSVGAsImage(prodSVGRaw, fileName2);
                            })})
                        )
            }
            stageContainer.append(subContainer);
            stageContainer.append(prodContainer);
            $("#stage_board").css("visibility", "visible")
            $("#reaction_data_board").css("visibility", "visible")
            stageContainer.css("border-top", "1px solid lightgrey");
            stageContainer.css("border-bottom", "1px solid lightgrey");
            stageContainer.find('.loaderParent').remove();

            }).catch((error) => {if(!alert("Reaction error :(")) window.location.href = "/target_list"});
       }
       else {if(!alert("Reaction error :(")) window.location.href = "/target_list"}
       // var endTime = performance.now()
       // console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)
 }, 500);

 const drawLoaders = (() => {
        let stageContainer = $("#reaction_container_main");
        stageContainer.css('borderRight', "none");
        let containerDim = $("#reaction_content").innerWidth();
        let molDim = Math.ceil($("#reaction_content").innerWidth() * 0.33);
        stageContainer.height(molDim);
        stageContainer.empty();
        let loaderContainer = $('<div>').css({
            backgroundColor: 'rgba(255,255,255, 0.8)',
            display:'flex',
            flex:'1',

            width: containerDim, height: molDim,
            position: 'absolute',
            zIndex: 1000
        }).addClass('loaderParent')

        let loader = $('<div>').css({
            position: 'absolute',
            top: molDim / 2 - 58, left: containerDim / 2 - 58,
        }).addClass('loader');

        loaderContainer.append(loader);
        stageContainer.append(loaderContainer);
 })

function prepareCondition(conditionText) {
    if (conditionText === "NULL")
        return '';
    let condition = "";
    condition += "<span>" + conditionText[0] + "</span>"
    for (let i = 1; i < conditionText.length; i++) {
        let prev = conditionText[i - 1];
        let next = '';
        if (i !== conditionText.length - 1)
            next = conditionText[i + 1];
        if (prev === 'p' && conditionText[i] === 'H' && /^\d+$/.test(next))
            conditionText = conditionText.substring(0, i + 1) + ' ' + conditionText[i + 1] + '.' + conditionText.substring(i + 3);

        if (!(/^\d+$/.test(prev))
            && prev !== ' ' && prev !== '-'
            && prev !== ':' && prev !== '('
            && prev !== '*' && prev !== ','
            && prev !== '.'
            && /^\d+$/.test(conditionText[i])
            && conditionText[i] !== '0'
            && !(/^\d+$/.test(next))
            && next !== 'o' && next !== 'º'
            && next !== '°' && next !== '℃'
            && next !== '.')
                condition += "<sub>" + conditionText[i] + "</sub>"
        else if ((/^\d+$/.test(prev))
            && (conditionText[i] === 'o' || conditionText[i] === 'º' || conditionText[i] === '°')
            && next === 'C') {
                condition += "<span>&#8451;</span>";
                i++;
            }
        else if ((/^\d+$/.test(prev))
            && (conditionText[i] === '℃'))
               condition += "<span>&#8451;</span>";
        else if (!(/^[a-z]/i.test(prev)) && !(/^\d+$/.test(prev))
            && conditionText[i] === '-' && (/^\d+$/.test(next)))
                condition += "<span> -</span>"
        else if ((prev === 'C' || prev === '℃')
            && conditionText[i] === 't' && next === 'o')
                condition += "<span> " + conditionText[i] + "</span>"
        else if (prev === 't' && conditionText[i] === 'o'
            && (next === 'r' || (/^\d+$/.test(next)) || next === '-'))
                condition += "<span>" + conditionText[i] + " </span>"
        else if (prev === '%' && (conditionText[i] !== ',' && conditionText[i] !== ')'))
                condition += "<span> " + conditionText[i] + "</span>"
        else if (prev === '(' && (/^\d+$/.test(conditionText[i])) && (/^[a-z]/i.test(next)))
                condition += "<span>" + conditionText[i] + " </span>"
        else if (conditionText[i] === ' ' && next === ',')
                continue;
        else
                condition += "<span>" + conditionText[i] + "</span>"
    }
    return condition;
}

function useSliderWidth() {
    const [sliderWidth, setSliderWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            const sliderWidth = document.getElementById("reaction_content").clientWidth;
            setSliderWidth(sliderWidth);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return sliderWidth;
}



const MainSlider = styled(Slider)((props) => ({
    height: 6,
    '& .MuiSlider-track': {
        border: 'none',
        opacity: props.active === 'true' ? 1 : 0,
    },
    '& .MuiSlider-markLabel': {
        fontSize: '0.9em',
        color: '#03a8c0',
        fontWeight:'bold',
        fontStyle:'italic',
    },
    '& .MuiSlider-rail': {
        opacity: 0.45,
    },
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,

        backgroundColor: props.active === 'true' ? 'black' : '#fff',
        border: '2px solid currentColor',

        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(50.2, 50.2, 50.2, 0.16)',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        padding: 0,
        width: 26,
        height: 26,
        borderRadius: '50% 50% 50% 0',
        //   backgroundColor: '#808080',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
}));

const SubSlider = styled(Slider)((props) => ({
    // color: '#52af77',
    width: props.fromstep ? ((props.fromstep) / (props.maxstep - 1)) * props.sliderwidth
                            : (0.5 / (props.maxstep - 1)) * props.sliderwidth,
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
        opacity: props.active === 'true' ? 1 : 0,
    },
    '& .MuiSlider-markLabel': {
           fontSize: '0.9em',
           color: '#03a8c0',
           fontWeight:'bold',
           fontStyle:'italic',
       },
    '& .MuiSlider-thumb': {
        height: 16,
        width: 16,

        backgroundColor: props.active === 'true' ? 'black' : '#fff',
        border: '2px solid currentColor',

        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(50.2, 50.2, 50.2, 0.16)',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        padding: 0,
        width: 26,
        height: 26,
        borderRadius: '50% 50% 50% 0',
        //   backgroundColor: '#808080',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
}));

function Reaction() {

    const auth = useAuth().user ? true : false;
    const [draw, setDraw] = useState({value: true}) // eslint-disable-line
    const [token, setToken] = useState({value: ""}) // eslint-disable-line
    const [marks, setMarks] = useState({value: []}) // eslint-disable-line
    const [subSliders, setSubSliders] = useState({value: []}) // eslint-disable-line
    const [myRatings, setMyRatings] = useState({rate: 0, fav: false}) // eslint-disable-line
    const [ratings, setRatings] = useState({value: {count_up: 0, count_down:0}}) // eslint-disable-line
    const {id} = useParams();
    const [currentStage, setCurrentStage] = useState(0);
    const [maxStage, setMaxStage] = useState({value: 0}); // eslint-disable-line
//    const [errorOpen, setErrorOpen] = React.useState(false);
    const [reactionName, setReactionName] = useState({value: "Name"}); // eslint-disable-line
    const [reactionAuthor, setReactionAuthor] = useState({value: "Author"}); // eslint-disable-line
    const [reactionYear, setReactionYear] = useState({value: "0000"}); // eslint-disable-line
    const [reactionDoi, setReactionDoi] = useState({value: "doi"}); // eslint-disable-line
    const [reaction, setReaction] = useState({value: "Reaction name"}); // eslint-disable-line
    const [currentReaction, setCurrentReaction] = useState({value: "Reaction name"}); // eslint-disable-line
    const [slider, setSlider] = useState("00");
    const [sliderLevel, setSliderLevel] = useState({value: 0}); // eslint-disable-line
    const [sliderSteps, setSliderSteps] = useState({value: [0,0,0,0,0,0,0,0,0]}); // eslint-disable-line

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"))
        if (user != null) {
            token.value = user.token
        }
        fetchReactionData(id, token.value);
        const debouncedResizeHandler = debounceCustom(() => {
            $("#mol3D_container").remove();
            drawLoaders();
            setDimension([window.innerWidth, window.innerWidth]);
        }, 100); // 100ms
        window.addEventListener('resize', debouncedResizeHandler);
        return () => window.removeEventListener('resize', debouncedResizeHandler);
    }, []); // eslint-disable-line

    const getSliderDataSub = (reactionIn, level, sliderLevel, sliderSteps) => {
        //console.log(level, sliderLevel)
         // console.log(sliderSteps)
        //console.log(reactionIn.build[reactionIn.content.focused])
        let reactionInIn = reactionIn.build[reactionIn.content.focused].sub[sliderSteps[level]]

        if(level < sliderLevel) {
           //console.log("rec")
           return getSliderDataSub(reactionInIn, ++level, sliderLevel, sliderSteps)
        }
        else {
           //console.log("ret")
           return reactionInIn;
        }

    }

    const getSliderData = (reactionIn, sliderLevel, sliderSteps) => {

        if (reaction.hasOwnProperty('content')) {

            if (sliderLevel === 0) {
                return {
                    stages: reactionIn.build,
                    maxStage: reactionIn.content.maxStage,
                    focused: reactionIn.content.focused,
                    name: reactionIn.content.name
                };
            } else {
                let reactionInIn = getSliderDataSub(reactionIn, 1, sliderLevel, sliderSteps);
                //console.log(reactionIn)
                return {
                       stages: reactionInIn.build,
                       maxStage: reactionInIn.content.maxStage,
                       focused: reactionInIn.content.focused,
                       name: reactionInIn.content.name,
                };
                //return reaction.substages[index - 1];
            }
        } else {
            return {stages: [], maxStage: 0, focused: 0};
        }
    }

    const sliderWidth = useSliderWidth();

    const isActive = (index) => {
        return index === slider ? 'true' : 'false';
    }

    const setupSubSliders = (reaction, level, width = sliderWidth) => {
            if(reaction.build[reaction.content.focused].sub)
                reaction.build[reaction.content.focused].sub.forEach((subStage, index) => {
                    if(!subStage.content)
                       subStage.content = {
                                            maxStage : subStage.build.length,
                                            focused : 0,
                                            name : reaction.content.name + " Subreaction"
                                         }
                      let marksIn = [];
                      subStage.build.forEach((step, id) => {
                           if(step.sub)
                               marksIn.push({
                                            value: id + 1,
                                            label: step.sub.length === 1 ? '+' + step.sub[0].build.length : '+X',
                                         })
                            else
                              marksIn.push({
                                           value: id + 1,
                                           label: '',
                                        })
                       })

                       subSliders.value.push(
                           <div key={`slider_${level}_${index}`}>
                               <SubSlider
                                   name={"substage_slider_" + level + "_" + index}
                                   id={"substage_slider" + level + "_" + index}
                                   size="small"
                                   defaultValue={1}
                                   value={subStage.content.focused + 1}
                                   min={subStage.content.maxStage > 1 ? 1 : 0}
                                   max={subStage.content.maxStage}
                                   marks={marksIn}
                                   aria-label={`Stage ${level}${index + 1}`}
                                   step={1}
                                   valueLabelDisplay="auto"
                                   onClick={() => changeSlider(index + 1, level)}
                                   onChange={(e) => subStage.content.maxStage > 1 ? onSliderChangeHandler(e, index + 1, level) : changeSlider(index + 1, level)}
                                   active={isActive(`${level}${index + 1}`)}
                                   sliderwidth={width || 0}
                                   maxstep={reaction.content.maxStage}
                                   fromstep={reaction.content.focused}
                               />
                           </div>

                           )
                           if(subStage.build[subStage.content.focused].sub && (slider === `${level}${index + 1}` || slider > `${level + 1}${0}`))
                              setupSubSliders(subStage, level + 1, getSliderWidth("substage_slider" + level + "_" + index));
                   })
                   //console.log(reaction)
  }


    if(draw.value) {
            drawLoaders();
            if(reaction.build) {
                currentReaction.value = getSliderData(reaction, sliderLevel.value, sliderSteps.value);
                $("#stage_info").html("Stage " + (currentReaction.value.focused + 1) + "/" + currentReaction.value.maxStage);
                //console.log(sliderLevel.value)

                drawStage(currentReaction.value, auth);
                subSliders.value = [];

                setupSubSliders(reaction, 1);

                marks.value = [];
                reaction.build?.forEach((step, id) => {
                    if(step.sub)
                        marks.value.push({
                                     value: id + 1,
                                     label: step.sub.length === 1 ? '+' + step.sub[0].build.length : '+X',
                                  })
                   else
                        marks.value.push({
                                     value: id + 1,
                                     label: '',
                                  })
                })
            }
    }
    else draw.value = true;

    // should be different func to handle each icon - placeholder
    const handleIconClick = (reactionType) => {
        if(reactionType === 2)
            sendFavorite(id, token.value);
        else
            sendRate(id, token.value, reactionType);
    }

//    const handleErrorClick = () => {
//        handleIconClick(40);
//        setErrorOpen(!errorOpen);
//    }

//    const sendOpinion = () => {
//        handleErrorClick();
//    }

    const [dimension, setDimension] = useState([ // eslint-disable-line
        window.innerWidth,
        window.innerHeight
    ]);

    const sendFavorite = (molecule, token) => {
            let bodyData = JSON.stringify({
               molecule: molecule,
               token: token,
            })
            fetch(`${process.env.REACT_APP_API_ADDRESS}/favorite`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: bodyData
                // credentials: 'include',
            })
           .then(response => {
               if(response.ok) {
                  let myRatingsOld = JSON.parse(JSON.stringify(myRatings))

                  myRatingsOld.fav = !myRatingsOld.fav;

                  draw.value = false;
                  setMyRatings(myRatingsOld)
              }
           })
    }

    const sendRate = (molecule, token, voteType) => {

            let bodyData = JSON.stringify({
               molecule: molecule,
               voteType: voteType,
               token: token,
            })

            fetch(`${process.env.REACT_APP_API_ADDRESS}/rate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: bodyData
                // credentials: 'include',
            })
            .then(response => {
               if(response.ok) {
                    let myRatingsOld = JSON.parse(JSON.stringify(myRatings))
                    if(voteType === -1) {
                       if(myRatingsOld.rate === -1) {
                           ratings.value.count_down -= 1;
                           myRatingsOld.rate = 0;
                       }
                       else {
                           if(myRatingsOld.rate === 1) {
                              ratings.value.count_up -= 1;
                           }
                           myRatingsOld.rate = -1;
                           ratings.value.count_down += 1;
                      }
                   }
                   else {
                      if(myRatingsOld.rate === 1) {
                          ratings.value.count_up -= 1;
                          myRatingsOld.rate = 0;
                      }
                      else {
                          if(myRatingsOld.rate === -1) {
                             ratings.value.count_down -= 1;
                          }
                          myRatingsOld.rate = 1;
                          ratings.value.count_up += 1;
                      }

                   }
                   draw.value = false;
                   setMyRatings(myRatingsOld)
               }
            })


    }

    const fetchReactionData = (id, token) => {
        let bodyData = JSON.stringify({
           token: token,
        })
        fetch(`${process.env.REACT_APP_API_ADDRESS}/molecule/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: bodyData
            // credentials: 'include',
        })
            .then(response => {
                if (!response.ok){
                   if(!alert("Reaction error :(")) window.location.href = "/target_list"
                }
                else
                    return response.json();
            })
            .then(data => {
                // console.log(data)
                /// DEV OPTIONS
                maxStage.value = data.build.length;
                reactionName.value = data.content.name;
                reactionAuthor.value = data.content.author;
                reactionYear.value = data.content.year;
                reactionDoi.value = data.content.doi;
                data.content.focused = 0;
                data.content.maxStage = data.build.length;
//                data.build.forEach(seq => {
//                    if (seq.sub) {
//                        seq.sub.forEach(subReaction => {
//                            subReaction.content = {
//                                                      maxStage : Object.values(seq.sub).length,
//                                                      focused : 0
//                                                   }
//                        })
//                    }
//                })
//                data.substages = [];
//                let id = -1;
//                data.build.forEach(seq => {
//                    if (seq.on_step !== id) {
//                        id = seq.on_step;
//                        data.substages.push({on_step: id, focused: -1, maxStage: 1, stages: [seq]});
//                    } else {
//                        data.substages[data.substages.length - 1].stages.push(seq);
//                        data.substages[data.substages.length - 1].maxStage++;
//                    }
//                })

                ratings.value = data.ratings
                setMyRatings(data.user_rate)
                setReaction(data);
                // console.log(data)
            })

    }

    const goLeft = () => {
        if (currentStage > 0) {
            setCurrentStage(currentStage - 1)
            if(sliderLevel.value > 0)
                sliderSteps.value[sliderLevel.value] = (Number(slider) - 1) % 10;

            if (currentReaction.value.focused >= 0) {

                if (slider === "00") {
                    reaction.content.focused--;
                } else if (Number(slider) > 10 && Number(slider) < 20) {
                    reaction.build[reaction.content.focused].sub[sliderSteps.value[1]].content.focused--;
                }
                else if (Number(slider) > 20 && Number(slider) < 30) {
                                    reaction
                                    .build[reaction.content.focused]
                                    .sub[sliderSteps.value[1]]
                                    .build[
                                         reaction
                                         .build[reaction.content.focused]
                                         .sub[sliderSteps.value[1]].content.focused
                                    ]
                                    .sub[sliderSteps.value[2]].content.focused--;
                }
                else if (Number(slider) > 30 && Number(slider) < 40) {
                                  reaction
                                    .build[reaction.content.focused]
                                    .sub[sliderSteps.value[1]]
                                    .build[
                                         reaction
                                         .build[reaction.content.focused]
                                         .sub[sliderSteps.value[1]].content.focused
                                    ]
                                    .sub[sliderSteps.value[2]]
                                    .build[
                                         reaction
                                         .build[reaction.content.focused]
                                         .sub[sliderSteps.value[1]]
                                         .build[
                                            reaction
                                              .build[reaction.content.focused]
                                              .sub[sliderSteps.value[1]].content.focused
                                         ].sub[sliderSteps.value[2]].content.focused
                                    ].sub[sliderSteps.value[3]].content.focused--;
                }
                drawLoaders();
                setReaction(reaction);
            }
        }
    };

    const goRight = () => {
        if (currentStage < currentReaction.value.maxStage - 1) {
            setCurrentStage(currentStage + 1)
            if(sliderLevel.value > 0)
                sliderSteps.value[sliderLevel.value] = (Number(slider) - 1) % 10;

            if (currentReaction.value.focused + 1 < currentReaction.value.maxStage) {
                   if (slider === "00") {
                        reaction.content.focused++;
                    } else if (Number(slider) > 10 && Number(slider) < 20) {
                        reaction.build[reaction.content.focused].sub[sliderSteps.value[1]].content.focused++;
                    }
                    else if (Number(slider) > 20 && Number(slider) < 30) {
                        reaction.build[reaction.content.focused]
                            .sub[sliderSteps.value[1]]
                            .build[
                                 reaction.build[reaction.content.focused]
                                   .sub[sliderSteps.value[1]].content.focused
                            ].sub[sliderSteps.value[2]].content.focused++;
                    }
                    else if (Number(slider) > 30 && Number(slider) < 40) {
                          reaction
                            .build[reaction.content.focused]
                            .sub[sliderSteps.value[1]]
                            .build[
                                 reaction
                                 .build[reaction.content.focused]
                                 .sub[sliderSteps.value[1]].content.focused
                            ]
                            .sub[sliderSteps.value[2]]
                            .build[
                                 reaction
                                 .build[reaction.content.focused]
                                 .sub[sliderSteps.value[1]]
                                 .build[
                                    reaction
                                      .build[reaction.content.focused]
                                      .sub[sliderSteps.value[1]].content.focused
                                 ].sub[sliderSteps.value[2]].content.focused
                            ].sub[sliderSteps.value[3]].content.focused++;
                    }
                    drawLoaders();
                    setReaction(reaction);
            }
         }
    };




    const onSliderChangeHandler = (event, index, level) => {

        if(slider !== `${level}${index}`) {
           changeSlider(index, level);
        }
        else {
            if (event.target.value - 1 !== currentStage) {
                setCurrentStage(event.target.value - 1);
            }
            if (event.target.value - 1 !== getSliderData(reaction, level, sliderSteps.value).focused) {

                  if (`${level}${index}` === "00") {
                      reaction.content.focused = event.target.value - 1;
                  }
                  else if (Number(slider) > 10 && Number(slider) < 20) {
                      reaction.build[reaction.content.focused].sub[sliderSteps.value[1]].content.focused = event.target.value - 1;
                  }
                  else if (Number(slider) > 20 && Number(slider) < 30) {
                      reaction.build[reaction.content.focused]
                          .sub[sliderSteps.value[1]]
                          .build[
                               reaction.build[reaction.content.focused]
                                 .sub[sliderSteps.value[1]].content.focused
                          ].sub[sliderSteps.value[2]].content.focused = event.target.value - 1;
                  }
                   else if (Number(slider) > 30 && Number(slider) < 40) {
                        reaction
                          .build[reaction.content.focused]
                          .sub[sliderSteps.value[1]]
                          .build[
                               reaction
                               .build[reaction.content.focused]
                               .sub[sliderSteps.value[1]].content.focused
                          ]
                          .sub[sliderSteps.value[2]]
                          .build[
                               reaction
                               .build[reaction.content.focused]
                               .sub[sliderSteps.value[1]]
                               .build[
                                  reaction
                                    .build[reaction.content.focused]
                                    .sub[sliderSteps.value[1]].content.focused
                               ].sub[sliderSteps.value[2]].content.focused
                          ].sub[sliderSteps.value[3]].content.focused = event.target.value - 1;
                   }
                   drawLoaders();
                   setReaction(reaction);
            }
        }
    }



//    const getStep = (index) => {
//        const step = reaction.substages[index].on_step - reaction.build[0].id;
//        return step;
//    }
    // let molDim = $("#reaction_content").innerWidth() * 0.33;
    // let stageContainer = $("#reaction_container_main");
    // stageContainer.height(molDim);
    // stageContainer.empty();


    const changeSlider = (index, level) => {
        if(slider !== `${level}${index}`) {
            sliderLevel.value = level;
            if(sliderLevel.value > 0)
                sliderSteps.value[level] = index - 1;
            //console.log( sliderSteps.value)
            setSlider(`${level}${index}`);
            setCurrentStage(getSliderData(reaction, level, sliderSteps.value).focused)
            drawLoaders();
        }
    }


    function getSliderWidth(id) {
       const sliderWidth = document.getElementById(id)?.clientWidth || 0;
       return sliderWidth;
    }
    //console.log(reaction)

//    {reaction.build? reaction.build[reaction.content?.focused].subReactions?.map((substage, index) => {
//                                       return (
//                                           <div key={"slider_" + index}>
//                                               <SubSlider
//                                                   name={"substage_slider" + index}
//                                                   id={"substage_slider" + index}
//                                                   size="small"
//                                                   defaultValue={1}
//                                                   value={substage.focused + 1}
//                                                   min={1}
//                                                   max={substage.maxStage}
//                                                   marks
//                                                   aria-label={"Stage " + index}
//                                                   step={1}
//                                                   valueLabelDisplay="auto"
//                                                   onChange={(e) => onSliderChangeHandler(e, index + 1)}
//                                                   onClick={() => setSlider(index + 1)}
//                                                   active={isActive(index + 1)}
//                                                   sliderwidth={sliderWidth}
//                                                   maxstep={reaction.content.maxStage}
//                                                   fromstep={reaction.content.focused}
//                                               />
//                                           </div>
//                                       )
//                                   }) : null}




    return (
        <div className={styles.reaction_content} id="reaction_content">
            <div id="reaction_data_board" style={{marginBottom: "20px", visibility: "hidden"}}>
                <h1 id="reaction_name" style={{display: 'inline'}}>{reactionName.value} </h1><br/>
                <p id="reaction_name" style={{display: 'inline'}}>&nbsp;Author: <b>{reactionAuthor.value}</b></p><br/>
                <p id="reaction_name" style={{display: 'inline'}}>&nbsp;Year: <b>{reactionYear.value}</b></p><br/>
                <p id="reaction_name" style={{display: 'inline'}}>&nbsp;doi: <a href={'https://doi.org/' + reactionDoi.value}
                                                                                className="link"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"><b>{reactionDoi.value}</b></a>
                </p>
                <div style={{position: "absolute", top: '0', right: '0'}}>

                    <IconButton sx={{color:'black'}} disabled={!token.value} onClick={() => {token.value && handleIconClick(1)}}>
                        {myRatings.rate === 1 ? <ThumbUpAlt/> : <ThumbUpOffAlt/>}
                    </IconButton>
                    <span className="rating" >{ratings.value.count_up} / </span>
                    <span className="rating">{ratings.value.count_down}</span>
                    <IconButton disabled={!token.value} sx={{color:'black'}}
                            onClick={() => {token.value && handleIconClick(-1)}}>
                        {myRatings.rate === -1 ?  <ThumbDownAlt/> : <ThumbDownOffAlt />}
                    </IconButton>

                    { token.value &&
                        <IconButton sx={{color:'black'}} onClick={() => handleIconClick(2)}>
                            {myRatings.fav ? <Favorite/> : <FavoriteBorder/>}
                        </IconButton>
                    }

                    {/* token.value &&
                        <CustomTooltip
                            title={
                                <React.Fragment>
                                    <div>
                                        <h2>Please share your insights with us!</h2>
                                        <TextField
                                            style={{width: '100%'}}
                                            id="standard-multiline-flexible"
                                            label="Your insights..."
                                            multiline
                                            maxRows={4}
                                            variant="standard"
                                        />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'right', padding: '10px 0 0'}}>
                                        <Button variant="text" id="right" onClick={() => sendOpinion()}>Send</Button>
                                    </div>
                                </React.Fragment>
                            }
                            PopperProps={{
                                disablePortal: true
                            }}
                            open={errorOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <IconButton onClick={() => handleErrorClick()}>
                                {iconClicks.includes(40) ? <Error/> : <ErrorOutline/>}
                            </IconButton>
                        </CustomTooltip>
                    */}
                </div>
            </div>

            <div className={styles.reaction_container} id="reaction_container_main">

            </div>
            <div id="stage_board" className={styles.stage_board} style={{visibility: 'hidden', marginTop: 30}}>

                <Button variant="text" id="left" onClick={goLeft}> <NavigateBeforeIcon/> </Button>
                <span id="stage_info" className={styles.stage_info}>Stage 1/{maxStage.value}</span>
                <Button variant="text" className={styles.right_button} id="right" onClick={goRight}> <NavigateNextIcon/></Button>

                <MainSlider
                    name="stage_slider"
                    id="stage_slider"
                    size="medium"
                    defaultValue={reaction.content?.focused + 1 || 1}
                    value={reaction.content?.focused + 1 || 1}
                    min={1}
                    max={reaction.content?.maxStage || 1}
                    marks={marks.value}
                    aria-label="Stage"
                    step={1}
                    valueLabelDisplay="auto"
                    onClick={() => changeSlider(0, 0)}
                    onChange={(e) => onSliderChangeHandler(e, 0, 0)}
                    active={isActive("00")}
                />
                {subSliders.value}
            </div>
        </div>

    );

}


export default Reaction;

//          {reaction.stages? reaction.stages[reaction.content?.focused + 1].sub.map((substage, index) => {
//                    return (
//                        <div key={"slider_" + index}>
//                            <SubSlider
//                                name={"substage_slider" + index}
//                                id={"substage_slider" + index}
//                                size="small"
//                                defaultValue={1}
//                                value={substage.focused + 1}
//                                min={1}
//                                max={substage.maxStage}
//                                marks
//                                aria-label={"Stage " + index}
//                                step={1}
//                                valueLabelDisplay="auto"
//                                onChange={(e) => onSliderChangeHandler(e, index + 1)}
//                                active={isActive(index + 1)}
//                                sliderwidth={sliderWidth}
//                                maxstep={reaction.content.maxStage}
//                                fromstep={getStep(index)}
//                            />
//                        </div>
//                    )
//                }) : null}


// function canvas_arrow(context, fromx, fromy, tox, toy) {
//     let headlen = 15; // length of head in pixels
//     let dx = tox - fromx;
//     let dy = toy - fromy;
//     let angle = Math.atan2(dy, dx);
//     context.lineWidth = 2;
//     context.beginPath();
//     context.moveTo(fromx, fromy);
//     context.lineTo(tox - 2, toy);
//     context.stroke();
//     context.lineJoin = "miter";
//     //context.moveTo(tox, toy);
//     context.moveTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
//     context.lineTo(tox, toy);
//     context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
//     context.stroke();
// }
// const onWheelHandler = (event) => {
//     event.preventDefault();
//     if (event.deltaY !== 0) {
//         if (event.deltaY < 0) {
//             goRight();
//         } else {
//             goLeft();
//         }
//     }
// };
//    <div className={styles.reaction_container} id="reaction_container_main" onWheel={(e) => onWheelHandler(e)}>

//const drawStage = debounce((reaction, auth) => {
//    var startTime = performance.now()
//
//
//    //downloadDomElementAsImage("reaction_content", "fileNameHere");
//
//    let maxStageNumber = reaction.maxStage;
//    let currentStageNumber = reaction.focused;
//    let name = reaction.name;
//
//
//    window.initRDKitModule()
//        .then(function (RDKit) {
//            RDKit.prefer_coordgen(true);
//            //$("#reaction_content").css("visibility", "hidden");
//            // console.log("RDKit version: " + RDKit.version());
//            let containerDim = $("#reaction_content").innerWidth();
//            let molDim = Math.ceil($("#reaction_content").innerWidth() * 0.33);
//            let stageContainer = $("#reaction_container_main");
//            stageContainer.height(molDim);
//            stageContainer.empty();
////            console.log(reaction)
//            if (reaction?.stages?.length > 0) {
//                let subSmileText = reaction.stages[currentStageNumber].substrates;
//                let prodSmileText = reaction.stages[currentStageNumber].product;
//                let catalystLigand = reaction.stages[currentStageNumber].catalyst_ligand;
//
//                let condText = reaction.stages[currentStageNumber].conditions;
//                let reactionName = reaction.stages[currentStageNumber].rx_name;
//                condText = condText.replaceAll('.', ', ')
//
//                let condSmileMols = [];
//                let condSmilesTexts = [];
//                let subReactionForMain = -1;
//                let subReactionForElse = [];
//                const smiles = subSmileText.split('.');
//                // const smilesCatLig = catalystLigand !== "NULL"? catalystLigand: [];
//                const smilesCatLig = catalystLigand !== "NULL" ? catalystLigand.split('.') : [];
//
//
//                if (smilesCatLig.length > 0)
//                    smiles.splice(1, 0, ...smilesCatLig); // smiles.splice(1, 0, ...smilesCatLig);
//
//
//                smiles.forEach((s,j) => {
//                    reaction.stages[currentStageNumber].sub?.forEach((e,i) => {
//
//                       if(e.build[e.build.length - 1].canonized_products === s) {
//                            if(j === 0)
//                                subReactionForMain = i;
//                            else
//                                subReactionForElse.push(i)
//                         }
//                         else {
//                             if(j === 0)
//                                 subReactionForMain = -1;
//                             else
//                                 subReactionForElse.push(-1)
//                         }
//                       })
//                })
//
//                let lastProduct = "";
//                if (currentStageNumber - 1 >= 0) {
//                    lastProduct = reaction.stages[currentStageNumber - 1].canonized_products
//                }
//
//
//                for (let i = 0; i < smiles.length; i++) {
//                    if (smiles[i] === lastProduct) {
//                         let element = smiles[i];
//                         smiles.splice(i, 1);
//                         smiles.unshift(element);
//                         break;
//                    }
//                }
//
//                subSmileText = smiles[0];
//                smiles.splice(0, 1);
//
//                condSmilesTexts = smiles;
//
//                for (let i = 0; i < condSmilesTexts.length; i++) {
//                    condSmileMols.push(RDKit.get_mol(condSmilesTexts[i]));
//                }
//
//                let subSmileMol = RDKit.get_mol(subSmileText);
//                let prodSmileMol = RDKit.get_mol(prodSmileText);
//                //console.log(prodSmileMol)
//
//                let subContainer = document.createElement("div");
//                subContainer.id = "substrate_container_" + currentStageNumber;
//                subContainer.style.width = molDim + "px";
//                subContainer.style.height = molDim + "px";
//                subContainer.style.display = "inline-block"
//                subContainer.style.float = "left"
//                if (currentStageNumber === 0) {
//                    subContainer.style.borderLeft = "1px solid lightgrey";
//                }
//                // console.log(subSmileText)
//
//                //subSmileMol.draw_to_canvas(subCanvas, -1, -1);
//                //molDim, molDim,
//                let mdetails = {};
//                mdetails['width'] = molDim;
//                mdetails['height'] = molDim;
//                mdetails['prepareMolsBeforeDrawing'] = true;
//                //mdetails['addChiralHs'] = false;
//                if (subReactionForMain !== -1) {
//                    subContainer.style.borderLeft = "1px solid lightgrey";
//                    subContainer.style.borderRight = "1px solid lightgrey";
//                    let val = subReactionForMain + 1;
//                    mdetails['legend'] = "Subreaction " + val;
//                }
//                //subSmileMol.condense_abbreviations();
//                //subSmileMol.normalize_depiction()
//                //subSmileMol.remove_hs_in_place()
//                //subSmileMol.straighten_depiction()
//
//                //let subSmileMol2 = RDKit.get_mol(subSmileMol.remove_hs())
//                let subSmileMolSvg = subSmileMol.get_svg_with_highlights(JSON.stringify(mdetails));
//
//
//                //subSmileMolSvg.id = "substrate_svg_" + currentStageNumber;
//                subContainer.innerHTML = subSmileMolSvg;
//
//                subContainer.style.cursor = "zoom-in"
//
//                subContainer.onclick = (e) => {
//                    e.preventDefault();
//                    e.stopPropagation();
//                    let fileName = name + " | substrate (stage " + (currentStageNumber + 1) + ")"
//                    let fileName2 = name + "_substrate(stage" + (currentStageNumber + 1) + ")"
//                    mdetails['width'] = 1280;
//                    mdetails['height'] = 1280;
//                    mdetails['prepareMolsBeforeDrawing'] = true;
//                    mdetails['legend'] = fileName;
//                    let subSmileMolSvgSave = subSmileMol.get_svg_with_highlights(JSON.stringify(mdetails));
//                    let mySVG64sub = window.btoa(subSmileMolSvgSave);
//                    $('<div>').css({
//                        background: 'RGBA(0,0,0,.5) url(data:image/svg+xml;base64,' + mySVG64sub + ') no-repeat center',
//                        backgroundSize: 'contain',
//                        width: '100%', height: '100%',
//                        position: 'fixed',
//                        zIndex: '10000',
//                        top: '0', left: '0',
//                        cursor: 'zoom-out'
//                    }).click(function (e) {
//                        e.preventDefault();
//                        e.stopPropagation();
//                        $(this).remove();
//                    }).appendTo('body')
//                        .append($('<div>').addClass('molecule_board')
//                            .append(function() { if(auth) return $('<i>').addClass('fa fa-save fa-3x molecule_board_icon')
//                                .click(function (e) {
//                                    e.preventDefault();
//                                    e.stopPropagation();
//                                    downloadMoleculeSVGAsImage(subSmileMolSvgSave, fileName2);
//                                })})
//                            .append($('<i>').addClass('fa fa-cube fa-3x molecule_board_icon')
//                                .click(function (e) {
////                                    let highlight = false;
////                                    let full3D = false;
//                                    //let viewer = null;
//                                    e.preventDefault();
//                                    e.stopPropagation();
//                                    let flexT = "column";
//                                    let size = window.innerHeight / 2;
//                                    if (window.innerWidth > window.innerHeight) {
//                                        size = window.innerWidth / 2;
//                                        flexT = "row";
//                                    }
//
//                                    $('<div>').attr('id', 'mol3D_container').css({
//                                        backgroundColor: 'RGBA(0,0,0,.5)',
//                                        width: '100%', height: '100%',
//                                        position: 'fixed',
//                                        zIndex: '10002',
//                                        top: '0', left: '0',
//                                        display: 'flex',
//                                        flexDirection: flexT,
//                                        alignItems: 'center',
//                                        verticalAlign: 'middle'
//                                    })
//                                        .append($('<div>').attr('id', 'mol3D').css({
//                                            backgroundColor: 'white',
//                                            width: size, height: size,
//                                            zIndex: '10003',
//                                            position: 'relative',
//
//                                            //transform: 'translate(-50%, -50%)'
//                                        }))
//                                        .append($('<div>').attr('id', 'mol2D').css({
//                                            backgroundColor: 'white',
//                                            width: size, height: size,
//                                            zIndex: '10004',
//                                            position: 'relative',
//
//                                            //transform: 'translate(-50%, -50%)'
//                                        }))
//                                        .append($('<div>').addClass('molecule_board_3D')
////                                            .append($('<i>').addClass('fa fa-highlighter fa-3x molecule_board_icon')
////                                                .click(function () {
////                                                    e.preventDefault();
////                                                    e.stopPropagation();
////                                                    highlight = !highlight;
////
////                                                    if (highlight)
////                                                        $(this).addClass('molecule_board_icon_used')
////                                                    else
////                                                        $(this).removeClass('molecule_board_icon_used')
////
////                                                    if (!full3D) {
////                                                        drawVisualization(subSmileText, "mol2D", "mol3D", highlight);
////                                                    } else {
////                                                        drawVisualizationFull3D(subSmileText, "mol2D", "mol3D", highlight);
////                                                    }
////                                                }))
////                                            .append($('<i>').addClass('fa fa-vr-cardboard fa-3x molecule_board_icon')
////                                                .click(function () {
////                                                    e.preventDefault();
////                                                    e.stopPropagation();
////                                                    full3D = !full3D;
////                                                    if (!full3D) {
////                                                        $(this).removeClass('molecule_board_icon_used')
////                                                        drawVisualization(subSmileText, "mol2D", "mol3D", highlight);
////                                                    } else {
////                                                        drawVisualizationFull3D(subSmileText, "mol2D", "mol3D", highlight);
////                                                        $(this).addClass('molecule_board_icon_used')
////                                                    }
////                                                }))
//                                            .append($('<i>').addClass('fa fa-xmark fa-3x molecule_board_icon')
//                                                .click(function () {
//                                                    e.preventDefault();
//                                                    e.stopPropagation();
//                                                    $("#mol3D").first().width(0).height(0);
//                                                    //viewer.clear();
//                                                    $("#mol3D").first().remove();
//                                                    $("#mol3D_container").remove();
//                                                }))
//                                        )
//                                        .appendTo('body')
//                                      drawVisualization(subSmileText, mySVG64sub);
////                                    if (!full3D)
////                                        drawVisualization(subSmileText, "mol2D", "mol3D", highlight);
////                                    else
////                                        drawVisualizationFull3D(subSmileText, "mol2D", "mol3D", highlight);
//                                }))
//                        )
//                }
//
//                stageContainer.append(subContainer);
//
//                let condContainer = document.createElement("div");
//                condContainer.id = "condition_container_" + currentStageNumber;
//                condContainer.style.width = molDim + "px";
//                condContainer.style.height = molDim + "px";
//                condContainer.style.float = "left"
//                condContainer.style.position = "relative";
//                condContainer.style.display = "flex"
//                condContainer.style.flexDirection = "column"
//                condContainer.style.alignItems = "center"
//                condContainer.style.overflow = "visible";
//
//
//                let condContainerUpperPartHeight = molDim * 0.47;
//                let condContainerUpperPart = document.createElement("div");
//                condContainerUpperPart.style.width = molDim + "px";
//                condContainerUpperPart.style.height = condContainerUpperPartHeight + "px";
//                condContainerUpperPart.style.display = "inline-block"
//                condContainerUpperPart.style.margin = "0"
//                condContainerUpperPart.style.padding = "0"
//                condContainerUpperPart.style.display = "flex"
//                condContainerUpperPart.style.flexDirection = "column"
//                condContainerUpperPart.style.alignItems = "center"
//                condContainerUpperPart.style.overflow = "visible";
//
//                let condContainerLowerPartHeight = molDim * 0.47;
//                let condContainerLowerPart = document.createElement("div");
//                condContainerLowerPart.style.width = molDim + "px";
//                condContainerLowerPart.style.height = condContainerLowerPartHeight + "px";
//                condContainerLowerPart.style.margin = "0"
//                condContainerLowerPart.style.padding = "0"
//                condContainerLowerPart.style.display = "flex"
//                condContainerLowerPart.style.flexDirection = "column"
//                condContainerLowerPart.style.alignItems = "center"
//                condContainerLowerPart.style.overflow = "visible";
//
//                let condSmileContainer = document.createElement("div");
//                condSmileContainer.id = "condition_smile_container_" + currentStageNumber;
//                condSmileContainer.style.margin = "0 auto";
//                condSmileContainer.style.padding = "0"
//                condSmileContainer.style.width = molDim + "px";
//                condSmileContainer.style.float = "left"
//                condSmileContainer.style.display = "flex"
//                condSmileContainer.style.flexDirection = "column"
//                condSmileContainer.style.alignItems = "center"
//
//                // let condSmileCanvas = document.createElement("canvas");
//                // condSmileCanvas.id = "condition_smile_canvas_" + currentStageNumber;
//                // // condSmileCanvas.style.width = $("#reaction_content").innerWidth() * 0.1 + "px";
//                // condSmileCanvas.style.height = containerDim * 0.1 + "px";
//
//                if (condSmileMols.length > 0) {
//                    for (let i = 0; i < condSmileMols.length; i++) {
//
//                        let condSmileContainerSub = document.createElement("div");
//                        condSmileContainerSub.id = "condition_smile_container_" + currentStageNumber + "_sub_" + i;
//                        condSmileContainerSub.style.display = "flex"
//
//                        let mdetails = {};
//                        mdetails['width'] = Math.round(condContainerUpperPartHeight * 0.7);
//                        mdetails['height'] = Math.round(condContainerUpperPartHeight * 0.7);
//                        mdetails['prepareMolsBeforeDrawing'] = true;
//
//
//                        if(subReactionForElse[i] !== undefined) {
//                            if (subReactionForElse[i] !== -1) {
//                                condSmileContainerSub.style.border = "1px solid lightgrey";
//                                let val = subReactionForElse[i] + 1
//                                mdetails['legend'] = "Subreaction " + val;
//                            }
//                        }
//                        let condSmileMolSvg = condSmileMols[i].get_svg_with_highlights(JSON.stringify(mdetails));
//                        condSmileContainerSub.innerHTML += condSmileMolSvg;
//                        condSmileContainerSub.style.cursor = "zoom-in";
//
//                        condSmileContainer.append(condSmileContainerSub)
//
//                        condSmileContainerSub.onclick = (e) => {
//                            e.preventDefault();
//                            e.stopPropagation();
//                            let fileName = name + " | element " + (i + 2) + " (stage " + (currentStageNumber + 1) + ")"
//                            let fileName2 = name + "_element" + (i + 2) + "(stage" + (currentStageNumber + 1) + ")"
//                            mdetails['width'] = 1280;
//                            mdetails['height'] = 1280;
//                            mdetails['prepareMolsBeforeDrawing'] = true;
//                            mdetails['legend'] = fileName;
//                            let condSmileMolSvgSave = condSmileMols[i].get_svg_with_highlights(JSON.stringify(mdetails));
//                            let mySVG64cond = window.btoa(condSmileMolSvgSave);
//                            $('<div>').css({
//                                background: 'RGBA(0,0,0,.5) url(data:image/svg+xml;base64,' + mySVG64cond + ') no-repeat center',
//                                backgroundSize: 'contain',
//                                width: '100%', height: '100%',
//                                position: 'fixed',
//                                zIndex: '10000',
//                                top: '0', left: '0',
//                                cursor: 'zoom-out'
//                            }).click(function (e) {
//                                e.preventDefault();
//                                e.stopPropagation();
//                                $(this).remove();
//                            }).appendTo('body')
//                                .append($('<div>').addClass('molecule_board')
//                                    .append(function() { if(auth) return $('<i>').addClass('fa fa-save fa-3x molecule_board_icon')
//                                        .click(function (e) {
//                                            e.preventDefault();
//                                            e.stopPropagation();
//                                            downloadMoleculeSVGAsImage(condSmileMolSvgSave, fileName2);
//                                        })})
//                                    .append($('<i>').addClass('fa fa-cube fa-3x molecule_board_icon')
//                                        .click(function (e) {
//                                            e.preventDefault();
//                                            e.stopPropagation();
////                                            let highlight = false;
////                                            let full3D = false;
//                                            //let viewer = null;
//                                            let flexT = "column";
//                                            let size = window.innerHeight / 2;
//                                            if (window.innerWidth > window.innerHeight) {
//                                                size = window.innerWidth / 2;
//                                                flexT = "row";
//                                            }
//
//                                            $('<div>').attr('id', 'mol3D_container').css({
//                                                backgroundColor: 'RGBA(0,0,0,.5)',
//                                                width: '100%', height: '100%',
//                                                position: 'fixed',
//                                                zIndex: '10002',
//                                                top: '0', left: '0',
//                                                display: 'flex',
//                                                flexDirection: flexT,
//                                                alignItems: 'center',
//                                            })
//                                                .append($('<div>').attr('id', 'mol3D').css({
//                                                    backgroundColor: 'white',
//                                                    width: size, height: size,
//                                                    zIndex: '10003',
//                                                    position: 'relative',
//
//                                                    //transform: 'translate(-50%, -50%)'
//                                                }))
//                                                .append($('<div>').attr('id', 'mol2D').css({
//                                                    backgroundColor: 'white',
//                                                    width: size, height: size,
//                                                    zIndex: '10004',
//                                                    position: 'relative',
//
//                                                    //transform: 'translate(-50%, -50%)'
//                                                }))
//                                                .append($('<div>').addClass('molecule_board_3D')
////                                                    .append($('<i>').addClass('fa fa-highlighter fa-3x molecule_board_icon')
////                                                        .click(function () {
////                                                            e.preventDefault();
////                                                            e.stopPropagation();
////                                                            highlight = !highlight;
////
////                                                            if (highlight)
////                                                                $(this).addClass('molecule_board_icon_used')
////                                                            else
////                                                                $(this).removeClass('molecule_board_icon_used')
////
////                                                            if (!full3D) {
////                                                                drawVisualization(condSmilesTexts[i], "mol2D", "mol3D", highlight);
////                                                            } else {
////                                                                drawVisualizationFull3D(condSmilesTexts[i], "mol2D", "mol3D", highlight);
////                                                            }
////                                                        }))
////                                                    .append($('<i>').addClass('fa fa-vr-cardboard fa-3x molecule_board_icon')
////                                                        .click(function () {
////                                                            e.preventDefault();
////                                                            e.stopPropagation();
////                                                            full3D = !full3D;
////                                                            if (!full3D) {
////                                                                $(this).removeClass('molecule_board_icon_used')
////                                                                drawVisualization(condSmilesTexts[i], "mol2D", "mol3D", highlight);
////                                                            } else {
////                                                                drawVisualizationFull3D(condSmilesTexts[i], "mol2D", "mol3D", highlight);
////                                                                $(this).addClass('molecule_board_icon_used')
////                                                            }
////                                                        }))
//                                                    .append($('<i>').addClass('fa fa-xmark fa-3x molecule_board_icon')
//                                                        .click(function () {
//                                                            e.preventDefault();
//                                                            e.stopPropagation();
//                                                            $("#mol3D").first().width(0).height(0);
//                                                            //viewer.clear();
//                                                            $("#mol3D").first().remove();
//                                                            $("#mol3D_container").remove();
//                                                        }))
//                                                )
//                                                .appendTo('body')
//                                              drawVisualization(condSmilesTexts[i], mySVG64cond);
////                                            if (!full3D)
////                                                drawVisualization(condSmilesTexts[i], "mol2D", "mol3D", highlight);
////                                            else
////                                                drawVisualizationFull3D(condSmilesTexts[i], "mol2D", "mol3D", highlight);
//                                        }))
//                                )
//                        }
//                    }
//                } else {
//                    condSmileContainer.style.height = "0";
//                    condSmileContainer.style.display = "flex"
//                }
//
//                let condTextContainer = document.createElement("div");
//                condTextContainer.id = "condition_text_container_" + currentStageNumber;
//                condTextContainer.style.width = molDim * 0.6 + "px";
//                condTextContainer.style.display = "table";
//                condTextContainer.style.margin = "0 auto";
//                condTextContainer.style.padding = "0"
//
//
//                let condTextContainerInner = document.createElement("div");
//                condTextContainerInner.id = "condition_text_container_inner_" + currentStageNumber;
//                condTextContainerInner.style.width = molDim * 0.6 + "px";
//                condTextContainerInner.style.display = "table-cell";
//                condTextContainerInner.style.verticalAlign = "bottom";
//                condTextContainerInner.style.margin = "0";
//                condTextContainerInner.style.padding = "0"
//                // console.log(condText)
//                let conditionText = prepareCondition(condText)
//
//                condTextContainerInner.innerHTML = conditionText
//                condTextContainer.append(condTextContainerInner)
//
//                let condArrowContainer = document.createElement("div");
//                condArrowContainer.id = "condition_arrow_container_" + currentStageNumber;
//                condArrowContainer.style.width = molDim + "px";
//                condArrowContainer.style.height = molDim * 0.07 + "px";
//                condArrowContainer.style.margin = "0";
//                condArrowContainer.style.padding = "0"
//
//                let arrow = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' + molDim + ' ' + molDim * 0.07 + '">' +
//                    '  <defs>' +
//                    '    <marker id="arrowhead" markerWidth="' + containerDim * 0.01 + '" markerHeight="' + containerDim * 0.01 + '"' +
//                    '    refX="' + containerDim * 0.005 + '" refY="' + containerDim * 0.005 + '" orient="auto">' +
//                    '      <polygon points="0 0, ' + containerDim * 0.01 + ' ' + containerDim * 0.005 + ', 0 ' + containerDim * 0.01 + '" />' +
//                    '    </marker>' +
//                    '  </defs>' +
//                    '  <line x1="' + molDim * 0.2 + '" ' +
//                    '        y1="' + containerDim * 0.013 + '" ' +
//                    '        x2="' + molDim * 0.8 + '" ' +
//                    '        y2="' + containerDim * 0.013 + '" stroke="#000"' +
//                    '  stroke-width="1" marker-end="url(#arrowhead)" />' +
//                    '</svg>'
//                condArrowContainer.innerHTML = arrow;
//                // let ctx = condArrowContainer.getContext("2d");
//                // canvas_arrow(ctx, condArrowContainer.width * 0.25, condArrowContainer.height * 0.06, condArrowContainer.width * 0.75, condArrowContainer.height * 0.06);
//
//                let condReactionNameContainer = document.createElement("div");
//                condReactionNameContainer.id = "condition_reaction_name_container_" + currentStageNumber;
//                condReactionNameContainer.style.width = molDim * 0.6 + "px";
//                condReactionNameContainer.style.display = "table";
//                condReactionNameContainer.style.margin = "0 auto";
//                condReactionNameContainer.style.padding = "0"
//
//                let condReactionNameSpan = document.createElement("span");
//                condReactionNameSpan.id = "condition_reaction_name_span_" + currentStageNumber;
//                condReactionNameSpan.style.width = molDim * 0.6 + "px";
//                condReactionNameSpan.style.display = "table-cell";
//                condReactionNameSpan.style.verticalAlign = "top";
//                condReactionNameSpan.style.margin = "0 auto";
//                condReactionNameSpan.style.fontWeight = "bold"
//                condReactionNameSpan.style.fontStyle = "italic"
//
//                condReactionNameSpan.innerText = reactionName;
//                condReactionNameContainer.append(condReactionNameSpan)
//
//                let condEmptyContainer = document.createElement("div");
//                condEmptyContainer.style.width = molDim + "px";
//                condEmptyContainer.style.display = "flex";
//
//                let condEmptyContainer2 = document.createElement("div");
//                condEmptyContainer2.style.width = molDim + "px";
//                condEmptyContainer2.style.display = "flex";
//
//                condContainerUpperPart.append(condEmptyContainer);
//                condContainerUpperPart.append(condSmileContainer);
//                condContainerUpperPart.append(condTextContainer);
//
//
//                condContainerLowerPart.append(condReactionNameContainer);
//                condContainerLowerPart.append(condEmptyContainer2);
//
//                condContainer.append(condContainerUpperPart);
//                condContainer.append(condArrowContainer);
//                condContainer.append(condContainerLowerPart);
//
//                stageContainer.append(condContainer);
//
//
//                let marginHeightUpperPart = condContainerUpperPartHeight - condSmileContainer.getBoundingClientRect().height
//                    - condTextContainer.getBoundingClientRect().height;
//                let marginHeightLowerPart = condContainerLowerPartHeight - condReactionNameContainer.getBoundingClientRect().height;
//                condEmptyContainer.style.height = marginHeightUpperPart + "px";
//                condEmptyContainer2.style.height = marginHeightLowerPart + "px";
//
//                let condContainerUpperPartHeightFin =
//                    condSmileContainer.getBoundingClientRect().height +
//                    condTextContainer.getBoundingClientRect().height + condEmptyContainer.getBoundingClientRect().height;
//
//                if (condContainerUpperPartHeightFin >= condContainerUpperPartHeight) {
//                    let condInfoButton = document.createElement("button");
//                    condInfoButton.id = "condition_info_button_" + currentStageNumber;
//                    $('<i>').addClass('fas fa-info-circle fa-2x info_icon').appendTo(condInfoButton);
//                    // condInfoButton.append(//.append($('<i>').addClass('fas fa-info-circle fa-2x info_icon'))
//                    condInfoButton.style.bottom = "0";
//                    condInfoButton.style.backgroundColor = "white";
//                    condInfoButton.style.border = "none";
//                    condInfoButton.style.cursor = "pointer";
//                    condInfoButton.onclick = () => {
//                        $('<div>').append(condSmileContainer).append(condTextContainer).css({
//                            backgroundColor: 'rgba(255,255,255,0.95)',
//                            width: molDim, height: molDim,
//                            position: 'absolute',
//                            zIndex: '10000',
//                            top: '0', left: '0',
//                            cursor: 'zoom-out',
//                            textAlign: 'center',
//                            overflowY: 'auto',
//                            overflowX: 'hidden'
//                        }).click(function () {
//                            $(this).remove();
//                        }).appendTo(condContainer);
//                    }
//
//                    condContainerUpperPart.innerHTML = "";
//                    condContainerUpperPart.append(condEmptyContainer);
//                    condContainerUpperPart.append(condInfoButton);
//
//                    marginHeightUpperPart = condContainerUpperPartHeight - condInfoButton.getBoundingClientRect().height;
//                    condEmptyContainer.style.height = marginHeightUpperPart + "px";
//
//                }
//
//                let prodContainer = document.createElement("div");
//                prodContainer.id = "product_container_" + currentStageNumber;
//                prodContainer.style.width = molDim + "px";
//                prodContainer.style.height = molDim + "px";
//                prodContainer.style.display = "inline-block"
//                prodContainer.style.float = "left"
//                mdetails = {};
//                if (currentStageNumber === maxStageNumber - 1) {
//                    prodContainer.style.borderLeft = "1px solid lightgrey";
//                    prodContainer.style.borderRight = "1px solid lightgrey";
//                    mdetails['legend'] = name;
//                }
//
//                mdetails['width'] = molDim;
//                mdetails['height'] = molDim;
//                mdetails['prepareMolsBeforeDrawing'] = true;
//                let prodSmileMolSvg = prodSmileMol.get_svg_with_highlights(JSON.stringify(mdetails));
//                //subSmileMolSvg.id = "substrate_svg_" + currentStageNumber;
//                prodContainer.innerHTML = prodSmileMolSvg;
//
//                prodContainer.style.cursor = "zoom-in"
//
//                prodContainer.onclick = (e) => {
//                    e.preventDefault();
//                    e.stopPropagation();
//                    let fileName = name + " | product (stage " + (currentStageNumber + 1) + ")";
//                    let fileName2 = name + "_product(stage" + (currentStageNumber + 1) + ")";
//                    mdetails['width'] = 1280;
//                    mdetails['height'] = 1280;
//                    mdetails['prepareMolsBeforeDrawing'] = true;
//                    mdetails['legend'] = fileName;
//                    if (currentStageNumber === maxStageNumber - 1) {
//                        mdetails['legend'] = name;
//                    }
//                    let prodSmileMolSvgSave = prodSmileMol.get_svg_with_highlights(JSON.stringify(mdetails));
//                    let mySVG64prod = window.btoa(prodSmileMolSvgSave);
//                    $('<div>').css({
//                        background: 'RGBA(0,0,0,.5) url(data:image/svg+xml;base64,' + mySVG64prod + ') no-repeat center',
//                        backgroundSize: 'contain',
//                        width: '100%', height: '100%',
//                        position: 'fixed',
//                        zIndex: '10000',
//                        top: '0', left: '0',
//                        cursor: 'zoom-out'
//                    }).click(function (e) {
//                        e.preventDefault();
//                        e.stopPropagation();
//                        $(this).remove();
//                    }).appendTo('body')
//                        .append($('<div>').addClass('molecule_board')
//                            .append(function() { if(auth) return $('<i>').addClass('fa fa-save fa-3x molecule_board_icon')
//                                .click(function (e) {
//                                    e.preventDefault();
//                                    e.stopPropagation();
//                                    downloadMoleculeSVGAsImage(prodSmileMolSvgSave, fileName2);
//                                })})
//                            .append($('<i>').addClass('fa fa-cube fa-3x molecule_board_icon')
//                                .click(function (e) {
//                                    e.preventDefault();
//                                    e.stopPropagation();
////                                    let highlight = false;
////                                    let full3D = false;
//                                    //let viewer = null;
//                                    let flexT = "column";
//                                    let size = window.innerHeight / 2;
//                                    if (window.innerWidth > window.innerHeight) {
//                                        size = window.innerWidth / 2;
//                                        flexT = "row";
//                                    }
//
//                                    $('<div>').attr('id', 'mol3D_container').css({
//                                        backgroundColor: 'RGBA(0,0,0,.5)',
//                                        width: '100%', height: '100%',
//                                        position: 'fixed',
//                                        zIndex: '10002',
//                                        top: '0', left: '0',
//                                        display: 'flex',
//                                        flexDirection: flexT,
//                                        alignItems: 'center',
//                                        verticalAlign: 'middle'
//                                    })
//                                        .append($('<div>').attr('id', 'mol3D').css({
//                                            backgroundColor: 'white',
//                                            width: size, height: size,
//                                            zIndex: '10003',
//                                            position: 'relative',
//
//                                            //transform: 'translate(-50%, -50%)'
//                                        }))
//                                        .append($('<div>').attr('id', 'mol2D').css({
//                                            backgroundColor: 'white',
//                                            width: size, height: size,
//                                            zIndex: '10004',
//                                            position: 'relative',
//
//                                            //transform: 'translate(-50%, -50%)'
//                                        }))
//                                        .append($('<div>').addClass('molecule_board_3D')
////                                            .append($('<i>').addClass('fa fa-highlighter fa-3x molecule_board_icon')
////                                                .click(function () {
////                                                    e.preventDefault();
////                                                    e.stopPropagation();
////                                                    highlight = !highlight;
////
////                                                    if (highlight)
////                                                        $(this).addClass('molecule_board_icon_used')
////                                                    else
////                                                        $(this).removeClass('molecule_board_icon_used')
////
////                                                    if (!full3D) {
////                                                        drawVisualization(prodSmileText, "mol2D", "mol3D", highlight);
////                                                    } else {
////                                                        drawVisualizationFull3D(prodSmileText, "mol2D", "mol3D", highlight);
////                                                    }
////                                                }))
////                                            .append($('<i>').addClass('fa fa-vr-cardboard fa-3x molecule_board_icon')
////                                                .click(function () {
////                                                    e.preventDefault();
////                                                    e.stopPropagation();
////                                                    full3D = !full3D;
////                                                    if (!full3D) {
////                                                        $(this).removeClass('molecule_board_icon_used')
////                                                        drawVisualization(prodSmileText, "mol2D", "mol3D", highlight);
////                                                    } else {
////                                                        drawVisualizationFull3D(prodSmileText, "mol2D", "mol3D", highlight);
////                                                        $(this).addClass('molecule_board_icon_used')
////                                                    }
////                                                }))
//                                            .append($('<i>').addClass('fa fa-xmark fa-3x molecule_board_icon')
//                                                .click(function () {
//                                                    e.preventDefault();
//                                                    e.stopPropagation();
//                                                    $("#mol3D").first().width(0).height(0);
//                                                    //viewer.clear();
//                                                    $("#mol3D").first().remove();
//                                                    $("#mol3D_container").remove();
//                                                }))
//                                        )
//                                        .appendTo('body')
//                                      drawVisualization(prodSmileText, mySVG64prod);
////                                    if (!full3D)
////                                        drawVisualization(prodSmileText, "mol2D", "mol3D", highlight);
////                                    else
////                                        drawVisualizationFull3D(prodSmileText, "mol2D", "mol3D", highlight);
//                                }))
//                        )
//                }
//
//                //prodSmileMol.draw_to_canvas(canvasProd, -1, -1);
//                stageContainer.append(prodContainer);
//                $("#stage_board").css("visibility", "visible")
//                $("#reaction_data_board").css("visibility", "visible")
//                stageContainer.css("border-top", "1px solid lightgrey");
//                stageContainer.css("border-bottom", "1px solid lightgrey");
//                //$("#reaction_content").css("visibility", "visible")
//            }
//        })
//        .catch((e) => {
//            // console.log("RDKit error:" + e);
//        });
//        var endTime = performance.now()
//        console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)
// }, 500);

//function drawVisualizationFull3D(smile, cont2DID, cont3DID, highlight) {
//
//    let size = $("#" + cont3DID).width();
//
//    let loaderContainer = $('<div>').attr('id', 'loader_3D').css({
//        backgroundColor: 'transparent',
//        width: size, height: size,
//        zIndex: '10010',
//        position: 'absolute',
//        top: '0', left: '0',
//    })
//
//    let loader = $('<div>').css({
//        position: 'absolute',
//        top: size / 2 - 50, left: size / 2 - 50,
//    }).addClass('loader');
//
//    loaderContainer.append(loader);
//
//    let loaderContainer1 = loaderContainer.clone();
//    loaderContainer1.attr('id', 'loader_3D_1')
//
//    $("#" + cont2DID).append(loaderContainer);
//    $("#" + cont3DID).append(loaderContainer1);
//
//    const Molecule2D = new window.MolViewer.Molecule()
//    Molecule2D.get2DFromSMILE(smile);
//    Molecule2D.parseMol();
//    Molecule2D.centre();
//
//    const mol2D = new window.MolViewer.Mol2D(Molecule2D, document.getElementById(cont2DID))
//
//    const Molecule3D = new window.MolViewer.Molecule()
//    Molecule3D.get3DFromSMILE(smile);
//    const mol3D = new window.MolViewer.Mol3D(null, document.getElementById(cont3DID), {
//        showfGroups: highlight,
//        showHs: false,
//        highlight: false
//    });
//
//
//    document.addEventListener("ajaxComplete", () => {
//            try {
//                mol3D.Molecule = Molecule3D;
//                Molecule3D.parseMol();
//                Molecule3D.centre();
//                $("#" + cont2DID).empty();
//                mol2D.init();
//                mol2D.draw();
//
//                $("#" + cont3DID).empty();
//                mol3D.init();
//                mol3D.draw();
//            } catch (e) {
//                drawVisualization(smile, cont2DID, cont3DID, highlight)
//            }
//        }
//    );
//}