import * as React from 'react';
import {useEffect} from 'react';
import $ from "jquery";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import {useNavigate} from "react-router-dom";
import {Button, TextField, InputAdornment} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TableSortLabel from '@mui/material/TableSortLabel';
import SearchIcon from "@mui/icons-material/Search";

import {
    ThumbUpAlt
} from '@mui/icons-material';

//function escape(str) {
//  return str.replaceAll(/[()+-]/g, match => {
//    return {
//      '(': '\\(',
//      ')': '\\)',
//      '+': '\\+',
//      '-': '\\-'
//    }[match]
//  })
//}

//function getHighlightedText(text, highlight) {
//    highlight = escape(highlight)
//    if (text) {
//        var parts = text.split(new RegExp(`(${highlight})`, "gi"));
//        return parts.map((part, index) => (
//            <React.Fragment key={index}>
//                {part.toLowerCase() === highlight.toLowerCase() ? (
//                    <b style={{backgroundColor: "rgba(3, 168, 192, 0.4)"}}>{part}</b>
//                ) : (
//                    part
//                )}
//            </React.Fragment>
//        ));
//    }
//
//}

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function getHighlightedText(text, highlight) {
    if (text) {
        var parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, "gi"));
        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {(part.toLowerCase() === highlight.toLowerCase()) ? (
                    <b style={{backgroundColor: "rgba(105, 105, 105, 0.2)"}}>{part}</b>
                ) : (
                    part
                )}
            </React.Fragment>
        ));
    }
}

const sxTableContainer = {
    "&::-webkit-scrollbar": {
       width: 10,
       height: 10
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "lightgray"
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "black",
        borderRadius: 2
    }
}

function MostLiked() {

    const navigate = useNavigate();
//    const [order, setOrder] = React.useState('asc');
//    const [orderBy, setOrderBy] = React.useState('name');
//    const [page, setPage] = React.useState({value: 0}); // eslint-disable-line
//    const [elementNumber, setElementNumber] = React.useState({value: 100}); // eslint-disable-line
    const [data, setData] = React.useState([]);
//    const [searchQuery, setSearchQuery] = React.useState({value: localStorage.getItem("searchQuery") || ""});

    const [nameSearch, setNameSearch] = React.useState(""); // eslint-disable-line
    const [authorSearch, setAuthorSearch] = React.useState(""); // eslint-disable-line
    const [yearSearch, setYearSearch] = React.useState(""); // eslint-disable-line
    const [tableContent, setTableContent] = React.useState({value: null});  // eslint-disable-line
//    const [token, setToken] = React.useState({value: ""}) // eslint-disable-line

    const fetchTargetListPageData = () => {
        fetch(`${process.env.REACT_APP_API_ADDRESS}/top`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
//            body: JSON.stringify({
//                token: token.value,
//                name: nameSearch.value,
//                author: authorSearch.value,
//                year: yearSearch.value,
//                order: order,
//                orderBy: orderBy
//            }),
            // credentials: 'include',
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                //elementNumber.value = data.count ? data.count : 0;
                if (data.results?.length > 0) {
                    setData(data.results);
                } else {
                    let dataIn = [];
                    dataIn.push({'id': -1, 'name': 'No results found'});
//                    page.value = 0;
                    setData(dataIn);
                }
            })
    }

    const handleNameSearchChange = (event) => {
        setNameSearch(event.target.value);

        //fetchTargetListPageData();
    }

    const handleAuthorSearchChange = (event) => {
        setAuthorSearch(event.target.value);

        //fetchTargetListPageData();
    }

    const handleYearSearchChange = (event) => {
        setYearSearch(event.target.value);

        //fetchTargetListPageData();
    }

//    const handleChangePage = (event, newPage) => {
//
//        page.value = newPage;
//        fetchTargetListPageData();
//    };

    const navigateToReaction = (id) => {
        if (id !== -1)
            navigate("/reaction/" + id);
    }

    const navigateToReactionNewTab = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (id !== -1)
           window.open("/reaction/" + id, '_blank').focus()
    }

    useEffect(() => {
//     if (localStorage.getItem("user"))
//        token.value = JSON.parse(localStorage.getItem("user")).token
     fetchTargetListPageData();
    }, []); // eslint-disable-line

    let maxHeight = window.innerHeight - $("#menu").height() - 120;
    if (isNaN(maxHeight)) {
        maxHeight = window.innerHeight;
    }

//    const handleSortRequest = (property) => {
//        const isAsc = orderBy === property && order === 'asc';
//        setOrder(isAsc ? 'desc' : 'asc');
//        setOrderBy(property);
//    };

    const sxHeadCell = {
        '& .MuiTableSortLabel-icon': {
            color: 'white !important',
        },
        '&.MuiTableSortLabel-root': {
            color: 'white !important',
        },
        '&.MuiTableSortLabel-root:hover': {
            color: 'white !important',
            '& .MuiTableSortLabel-icon': {
                color: 'black !important',
                '& .MuiTableSortLabel-icon:hover': {
                  color: 'black !important',
                }
            },
        },
    }

//    eventBus.on("searchChange", (data) => {
//        // console.log('data', data)
//        setSearchQuery(data)
//    });

    useEffect(() => {
        if (data.length !== 0) {

            setTableContent({
                value: data.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}
                          sx={{
                            '&.MuiTableRow-root:hover':{
                              backgroundColor: 'rgba(3, 168, 192, 0.1)'
                            },
                           }}
                                  onClick={() => navigateToReaction(row.id)} style={{cursor: "pointer"}}>
                            <TableCell width={'35%'} key={'name'} align={'center'} variant={'body'}
                                       style={{fontStyle: "bold"}}>
                                <h4>{getHighlightedText(row.name, nameSearch)}{}</h4>
                            </TableCell>
                            <TableCell width={'25%'} key={'author'} align={'center'} variant={'body'}
                                       style={{fontStyle: "bold"}}>
                                {<h4>{getHighlightedText(row.author, authorSearch)}{}</h4>}
                            </TableCell>
                            <TableCell width={'20%'} key={'year'} align={'center'} variant={'body'}
                                       style={{fontStyle: "bold"}}>
                                {<h4>{getHighlightedText(row.year, yearSearch)}{}</h4>}
                            </TableCell>
                           <TableCell width={'10%'} key={'likes'} align={'center'} variant={'body'}
                                                               style={{
                                                                   fontStyle: "bold"
                                                               }}>{/*<div id={"target_container_" + row.id}></div>*/}
                                                        <h4><ThumbUpAlt fontSize="small"
                                                        sx={{color:'#03a8c0', margin:0, verticalAlign:'middle'}}/>{row.likes}</h4>
                              </TableCell>
                            <TableCell width={'10%'} key={'go'} align={'center'} variant={'body'}
                                       style={{
                                           fontStyle: "bold"
                                       }}>{/*<div id={"target_container_" + row.id}></div>*/}
                                <Button variant="text"
                                     sx={{
                                        ':hover': {
                                          backgroundColor: 'rgba(3, 168, 192, 0.1)',
                                        },
                                      }}
                                    onClick={(e) => navigateToReactionNewTab(e, row.id)}>
                                    <NavigateNextIcon/></Button>
                            </TableCell>
                        </TableRow>
                    );
                })
            });
            let loaderContainer = $("#loaderContainer");
            loaderContainer.remove();
        } else {
            let tableContainer = $("#tableBody");
            let tableWidth = $('#table').innerWidth();
            let loaderContainer = document.createElement("div");
            loaderContainer.setAttribute("id", "loaderContainer");
            loaderContainer.style.height = 200 + "px";
            loaderContainer.style.position = "relative";
            let loader = document.createElement("div");
            loader.className = 'loader';
            loader.style.left = tableWidth / 2 - 50 + "px"
            loader.style.top = 200 / 2 - 50 + "px"

            loaderContainer.append(loader);
            tableContainer.append(loaderContainer);
        }
    }, [data, nameSearch, yearSearch, authorSearch]); // eslint-disable-line

    return (<>
            <h1 style={{textAlign: 'center', margin:'20px'}}>
                Most Liked Reactions <ThumbUpAlt fontSize="large"
                     sx={{color:'#03a8c0', verticalAlign:'middle'}}/></h1>
            <Paper sx={{width: '60%', overflow: 'hidden', margin: '30px 20% 0px 20%'}}>
                <TableContainer
                    style={{maxHeight: maxHeight, minHeight: 100}}
                    sx={sxTableContainer}>
                    <Table stickyHeader aria-label="sticky table" id='table'>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={'35%'}
                                    key={'name'}
                                    align={'center'}
                                    variant={'head'}
                                    sortDirection={false}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{backgroundColor: "black", padding: '8px 16px'}}>
                                        <TableSortLabel
                                            active={false}
                                            hideSortIcon={true}
                                            sx={sxHeadCell}
                                        >
                                            <h3>Name</h3>
                                        </TableSortLabel>
                                    </div>
                                    <div style={{padding: '8px 16px', background: 'rgb(105 105 105 / 20%)'}}>
                                        <TextField
                                            style={{
                                                display: 'flex',
                                                width: '80%',
                                                minWidth: '80px',
                                                margin: 'auto'
                                            }}
                                            defaultValue={nameSearch}
                                            id="name-search"
                                            className="text"
                                            onChange={handleNameSearchChange}
                                            variant="filled"
                                            placeholder="Search..."
                                            size="small"
                                            hiddenLabel
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                                 sx: {
                                                        '&.MuiFilledInput-root': {
                                                            height: '30px',
                                                            fontSize: '14px',
                                                            background: 'transparent'
                                                        },
                                                        '&.MuiFilledInput-root:hover': {
                                                            backgroundColor: 'rgb(105 105 105 / 10%)'
                                                        }
                                                    },

                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    width={'25%'}
                                    key={'author'}
                                    align={'center'}
                                    variant={'head'}
                                    sortDirection={false}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{backgroundColor: "black", padding: '8px 16px'}}>
                                        <TableSortLabel
                                            active={false}
                                            hideSortIcon={true}
                                            sx={sxHeadCell}
                                        >
                                            <h3>Author</h3>
                                        </TableSortLabel>
                                    </div>
                                    <div style={{padding: '8px 16px', background: 'rgb(105 105 105 / 20%)'}}>
                                        <TextField
                                            style={{
                                                display: 'flex',
                                                width: '80%',
                                                minWidth: '80px',
                                                margin: 'auto'
                                            }}
                                            defaultValue={authorSearch}
                                            id="author-search"
                                            className="text"
                                            onChange={handleAuthorSearchChange}
                                            variant="filled"
                                            placeholder="Search..."
                                            size="small"
                                            hiddenLabel
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.MuiFilledInput-root': {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        background: 'transparent'
                                                    },
                                                    '&.MuiFilledInput-root:hover': {
                                                        backgroundColor: 'rgb(105 105 105 / 10%)'
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    width={'20%'}
                                    key={'year'}
                                    align={'center'}
                                    variant={'head'}
                                    sortDirection={false}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{backgroundColor: "black", padding: '8px 16px'}}>
                                        <TableSortLabel
                                            active={false}
                                            hideSortIcon={true}
                                            sx={sxHeadCell}
                                        >
                                            <h3>Year</h3>
                                        </TableSortLabel>
                                    </div>
                                    <div style={{padding: '8px 16px', background: 'rgb(105 105 105 / 20%)'}}>
                                        <TextField
                                            style={{
                                                display: 'flex',
                                                width: '90%',
                                                minWidth: '80px',
                                                margin: 'auto'
                                            }}
                                            defaultValue={yearSearch}
                                            id="author-search"
                                            className="text"
                                            onChange={handleYearSearchChange}
                                            variant="filled"
                                            placeholder="Search..."
                                            size="small"
                                            hiddenLabel
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.MuiFilledInput-root': {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        background: 'transparent'
                                                    },
                                                    '&.MuiFilledInput-root:hover': {
                                                        backgroundColor: 'rgb(105 105 105 / 10%)'
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    width={'10%'}
                                    key={'likes'}
                                    align={'center'}
                                    variant={'head'}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{
                                        backgroundColor: "black",
                                        padding: '8px 16px',
                                        minHeight: '25px'
                                    }}></div>
                                    <div style={{
                                        padding: '8px 16px',
                                        background: 'rgb(105 105 105 / 20%)',
                                        minHeight: '30px'
                                    }}></div>
                                </TableCell>
                                <TableCell
                                    width={'10%'}
                                    key={'go'}
                                    align={'center'}
                                    variant={'head'}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{
                                        backgroundColor: "black",
                                        padding: '8px 16px',
                                        minHeight: '25px'
                                    }}></div>
                                    <div style={{
                                        padding: '8px 16px',
                                        background: 'rgb(105 105 105 / 20%)',
                                        minHeight: '30px'
                                    }}></div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody id='tableBody'>
                            {tableContent.value}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </>
    );
}

export default MostLiked;
