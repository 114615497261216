import * as React from 'react';
import {useEffect} from 'react';
import $ from "jquery";
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import PaginationItem from '@mui/material/PaginationItem';
import Pagination from '@mui/material/Pagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import {useNavigate} from "react-router-dom";
import {Button, TextField, InputAdornment} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TableSortLabel from '@mui/material/TableSortLabel';
import SearchIcon from '@mui/icons-material/Search';
import eventBus from "./components/EventBus";
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';

import {
    ViewList
} from '@mui/icons-material';


//function escape(str) {
//  return str.replaceAll(/[()+-]/g, match => {
//    return {
//      '(': '\\(',
//      ')': '\\)',
//      '+': '\\+',
//      '-': '\\-'
//    }[match]
//  })
//}

//function getHighlightedText(text, highlight) {
//    highlight = escape(highlight)
//    if (text) {
//        var parts = text.split(new RegExp(`(${highlight})`, "gi"));
//        return parts.map((part, index) => (
//            <React.Fragment key={index}>
//                {part.toLowerCase() === highlight.toLowerCase() ? (
//                    <b style={{backgroundColor: "rgba(3, 168, 192, 0.4)"}}>{part}</b>
//                ) : (
//                    part
//                )}
//            </React.Fragment>
//        ));
//    }
//
//}

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function getHighlightedText(text, highlight, highlight2) {
    if (text) {
        if(text !== 'No results found') {
            if(highlight){
                let parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, "gi"));
                return parts.map((part, index) => (
                    <React.Fragment key={index}>
                        {(part.toLowerCase() === highlight.toLowerCase()) ? (
                            <b style={{backgroundColor: "rgba(3, 168, 192, 0.4)"}}>{part}</b>
                        ) : (index === 0 && highlight2.length <= part.length && part.substring(0, highlight2.length).toLowerCase() === highlight2.toLowerCase()) ? (
                            <><b style={{backgroundColor: "rgba(105, 105, 105, 0.2)"}}>{part.substring(0, highlight2.length)}</b>
                            {part.substring(highlight2.length)}</>
                        ) : (index === 0 && highlight2.length > part.length && part.toLowerCase() === highlight2.substring(0, part.length).toLowerCase()) ? (
                            <b style={{backgroundColor: "rgba(105, 105, 105, 0.2)"}}>{part}</b>
                        ) : (
                            part
                        )}
                    </React.Fragment>
                ));
            }
            else {
                let parts = text.split(new RegExp(`(${escapeRegExp(highlight2)})`, "gi"));
                    return parts.map((part, index) => (
                        <React.Fragment key={index}>
                            {(part.toLowerCase() === highlight2.toLowerCase()) ? (
                                <b style={{backgroundColor: "rgba(105, 105, 105, 0.2)"}}>{part}</b>
                            ) : (
                                part
                            )}
                        </React.Fragment>
                    ));
            }
        }
        else return text
    }
}

const sxTableContainer = {
//    "&::-webkit-scrollbar": {
//        width: 10,
//        height: 10
//    },
//    "&::-webkit-scrollbar-track": {
//        backgroundColor: "lightgray"
//    },
//    "&::-webkit-scrollbar-thumb": {
//        backgroundColor: "black",
//        borderRadius: 2
//    }
}

function TargetList() {

    const navigate = useNavigate();
    const [order, setOrder] = React.useState(localStorage.getItem("searchQueryOrder") || 'asc');
    const [orderBy, setOrderBy] = React.useState(localStorage.getItem("searchQueryOrderBy") || 'name');
    const [page, setPage] = React.useState({value: parseInt(localStorage.getItem("searchQueryPage")) || 1}); // eslint-disable-line
    const [elementNumber, setElementNumber] = React.useState({value: 100}); // eslint-disable-line
    const [data, setData] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState({value: localStorage.getItem("searchQuery") || ""});

    const [nameSearch, setNameSearch] = React.useState({value: localStorage.getItem("searchQueryName") || ""}); // eslint-disable-line
    const [authorSearch, setAuthorSearch] = React.useState({value: localStorage.getItem("searchQueryAuthor") || ""}); // eslint-disable-line
    const [yearSearch, setYearSearch] = React.useState({value: localStorage.getItem("searchQueryYear") || ""}); // eslint-disable-line
    const [tableContent, setTableContent] = React.useState({value: null}); // eslint-disable-line


    const fetchTargetListPageData = () => {
        fetch(`${process.env.REACT_APP_API_ADDRESS}/list?page=${page.value}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                search: searchQuery.value,
                name: nameSearch.value,
                author: authorSearch.value,
                year: yearSearch.value,
                order: order,
                orderBy: orderBy
            }),
            // credentials: 'include',
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                elementNumber.value = data.count ? data.count : 0;
                if (data.count > 0) {
                    setData(data.results);
                } else {
                    let dataIn = [];
                    dataIn.push({'id': -1, 'name': 'No results found'});
                    localStorage.setItem("searchQueryPage", 1)
                    page.value = 1;
                    setData(dataIn);
                }
            })
    }

    const handleNameSearchChange = (event) => {
        nameSearch.value = event.target.value;
        localStorage.setItem("searchQueryName", nameSearch.value)
        page.value = 1;
        localStorage.setItem("searchQueryPage", 1)
        fetchTargetListPageData();
    }

    const handleAuthorSearchChange = (event) => {
        authorSearch.value = event.target.value;
        localStorage.setItem("searchQueryAuthor", authorSearch.value)
        page.value = 1;
        localStorage.setItem("searchQueryPage", 1)
        fetchTargetListPageData();
    }

    const handleYearSearchChange = (event) => {
        yearSearch.value = event.target.value;
        localStorage.setItem("searchQueryYear", yearSearch.value)
        page.value = 1;
        localStorage.setItem("searchQueryPage", 1)
        fetchTargetListPageData();
    }

    const handleChangePage = (event, newPage) => {
        if(!event.target.classList.contains('MuiPaginationItem-firstLast')) {
            localStorage.setItem("searchQueryPage", newPage)
            page.value = newPage;
            fetchTargetListPageData();
        }
        else {
            if(event.target.getAttribute('aria-label').includes('last')) {
                handleChangePageCustom(page.value + 10)
            }
            else {
                handleChangePageCustom(page.value - 10)
            }
        }
    };

   const handleChangePageCustom = (newPage) => {
        if(newPage < 1)
            newPage = 1;
        if(newPage > Math.ceil(elementNumber.value / 10))
            newPage = Math.ceil(elementNumber.value / 10);
        localStorage.setItem("searchQueryPage", newPage)
        page.value = newPage;
        fetchTargetListPageData();
    };

    const navigateToReaction = (id) => {
        if (id !== -1)
            navigate("/reaction/" + id);
    }
    const navigateToReactionNewTab = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (id !== -1)
           window.open("/reaction/" + id, '_blank').focus()
    }

    useEffect(() => {
        fetchTargetListPageData();
    }, [searchQuery, nameSearch, authorSearch, yearSearch, order, orderBy]); // eslint-disable-line

    let maxHeight = window.innerHeight - $("#menu").height() - 120;
    if (isNaN(maxHeight)) {
        maxHeight = window.innerHeight;
    }

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        localStorage.setItem("searchQueryOrder", isAsc ? 'desc' : 'asc')
        localStorage.setItem("searchQueryOrderBy", property)
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sxHeadCell = {
        '& .MuiTableSortLabel-icon': {
            color: 'white !important',
        },
        '&.MuiTableSortLabel-root': {
            color: 'white',
        },
        '&.MuiTableSortLabel-root:hover': {
            color: '#ffffffc9',
            '& .MuiTableSortLabel-icon': {
                color: '#ffffffc9 !important',
            },
        },
    }

    eventBus.on("searchChange", (data) => {
        // console.log('data', data)
        page.value = 1;
        setSearchQuery(data)
    });

    const [windowDimensions, setWindowDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      useEffect(() => {
        const handleResize = () => {
          setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);


    useEffect(() => {
        if (data.length !== 0) {
            // console.log('data', data)
            setTableContent({
                value: data.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}
                            sx={{
                                '&.MuiTableRow-root:hover':{
                                  backgroundColor: 'rgba(3, 168, 192, 0.1)'
                                },
                               }}
                                  onClick={() => navigateToReaction(row.id)} style={{cursor: "pointer"}}>
                            <TableCell width={'40%'} key={'name'} align={'center'} variant={'body'}
                                       style={{fontStyle: "bold"}}>
                                <h4>{getHighlightedText(row.name, searchQuery.value, nameSearch.value)}{}</h4>
                            </TableCell>
                            <TableCell width={'30%'} key={'author'} align={'center'} variant={'body'}
                                       style={{fontStyle: "bold"}}>
                                {<h4>{getHighlightedText(row.author, searchQuery.value, authorSearch.value)}{}</h4>}
                            </TableCell>
                            <TableCell width={'20%'} key={'year'} align={'center'} variant={'body'}
                                       style={{fontStyle: "bold"}}>
                                {<h4>{getHighlightedText(row.year, searchQuery.value, yearSearch.value)}{}</h4>}
                            </TableCell>
                            <TableCell width={'10%'} key={'go'} align={'center'} variant={'body'}
                                       style={{
                                           fontStyle: "bold"
                                       }}>{/*<div id={"target_container_" + row.id}></div>*/}
                                <Button variant="text"
                                    sx={{
                                        ':hover': {
                                          backgroundColor: 'rgba(3, 168, 192, 0.1)',
                                        },
                                      }}
                                    onClick={(e) => navigateToReactionNewTab(e, row.id)}>
                                <NavigateNextIcon/></Button>
                            </TableCell>
                        </TableRow>
                    );
                })
            });
            let loaderContainer = $("#loaderContainer");
            loaderContainer.remove();
        } else {
            let tableContainer = $("#tableBody");
            let tableWidth = $('#table').innerWidth();
            let loaderContainer = document.createElement("div");
            loaderContainer.setAttribute("id", "loaderContainer");
            loaderContainer.style.height = 200 + "px";
            loaderContainer.style.position = "relative";
            let loader = document.createElement("div");
            loader.className = 'loader';
            loader.style.left = tableWidth / 2 - 50 + "px"
            loader.style.top = 200 / 2 - 50 + "px"

            loaderContainer.append(loader);
            tableContainer.append(loaderContainer);
        }
    }, [data]); // eslint-disable-line

    return (<>
            <h1 style={{textAlign: 'center', margin:'20px'}}>
                            Target List <ViewList fontSize="large"
                               sx={{color:'#03a8c0', verticalAlign:'middle'}}/></h1>
            <Paper sx={{width: '60%', overflow: 'hidden', margin: '30px 20% 0px 20%'}}>
                <TableContainer
                    style={{maxHeight: maxHeight, minHeight: 100}}
                    sx={sxTableContainer}>
                    <Table stickyHeader aria-label="sticky table" id='table'>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={'40%'}
                                    key={'name'}
                                    align={'center'}
                                    variant={'head'}
                                    sortDirection={orderBy === 'name' ? order : false}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{backgroundColor: "black", padding: '8px 16px'}}>
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={() => handleSortRequest('name')}
                                            sx={sxHeadCell}
                                        >
                                            <h3>Name</h3>
                                        </TableSortLabel>
                                    </div>
                                    <div style={{padding: '8px 16px', background: 'rgb(105 105 105 / 20%)'}}>
                                        <TextField
                                            style={{
                                                display: 'flex',
                                                width: '80%',
                                                minWidth: '80px',
                                                margin: 'auto'
                                            }}
                                            defaultValue={nameSearch.value}
                                            id="name-search"
                                            className="text"
                                            onChange={handleNameSearchChange}
                                            variant="filled"
                                            placeholder="Search..."
                                            size="small"
                                            hiddenLabel
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.MuiFilledInput-root': {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        background: 'transparent'
                                                    },
                                                    '&.MuiFilledInput-root:hover': {
                                                        backgroundColor: 'rgb(105 105 105 / 10%)'
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    width={'30%'}
                                    key={'author'}
                                    align={'center'}
                                    variant={'head'}
                                    sortDirection={orderBy === 'author' ? order : false}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{backgroundColor: "black", padding: '8px 16px'}}>
                                        <TableSortLabel
                                            active={orderBy === 'author'}
                                            direction={orderBy === 'author' ? order : 'asc'}
                                            onClick={() => handleSortRequest('author')}
                                            sx={sxHeadCell}
                                        >
                                            <h3>Author</h3>
                                        </TableSortLabel>
                                    </div>
                                    <div style={{padding: '8px 16px', background: 'rgb(105 105 105 / 20%)'}}>
                                        <TextField
                                            style={{
                                                display: 'flex',
                                                width: '80%',
                                                minWidth: '80px',
                                                margin: 'auto',
                                            }}
                                            defaultValue={authorSearch.value}
                                            id="author-search"
                                            className="text"
                                            onChange={handleAuthorSearchChange}
                                            variant="filled"
                                            placeholder="Search..."
                                            size="small"
                                            hiddenLabel
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.MuiFilledInput-root': {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        background: 'transparent'
                                                    },
                                                    '&.MuiFilledInput-root:hover': {
                                                        backgroundColor: 'rgb(105 105 105 / 10%)'
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    width={'20%'}
                                    key={'year'}
                                    align={'center'}
                                    variant={'head'}
                                    sortDirection={orderBy === 'year' ? order : false}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{backgroundColor: "black", padding: '8px 16px'}}>
                                        <TableSortLabel
                                            active={orderBy === 'year'}
                                            direction={orderBy === 'year' ? order : 'asc'}
                                            onClick={() => handleSortRequest('year')}
                                            sx={sxHeadCell}
                                        >
                                            <h3>Year</h3>
                                        </TableSortLabel>
                                    </div>
                                    <div style={{padding: '8px 16px', background: 'rgb(105 105 105 / 20%)'}}>
                                        <TextField
                                            style={{
                                                display: 'flex',
                                                width: '90%',
                                                minWidth: '80px',
                                                margin: 'auto'
                                            }}
                                            defaultValue={yearSearch.value}
                                            id="author-search"
                                            className="text"
                                            onChange={handleYearSearchChange}
                                            variant="filled"
                                            placeholder="Search..."
                                            size="small"
                                            hiddenLabel
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    '&.MuiFilledInput-root': {
                                                        height: '30px',
                                                        fontSize: '14px',
                                                        background: 'transparent'
                                                    },
                                                    '&.MuiFilledInput-root:hover': {
                                                        backgroundColor: 'rgb(105 105 105 / 10%)'
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    width={'10%'}
                                    key={'go'}
                                    align={'center'}
                                    variant={'head'}
                                    style={{
                                        height: 40,
                                        padding: 0,
                                    }}
                                >
                                    <div style={{
                                        backgroundColor: "black",
                                        padding: '8px 16px',
                                        minHeight: '25px'
                                    }}></div>
                                    <div style={{
                                        padding: '8px 16px',
                                        background: 'rgb(105 105 105 / 20%)',
                                        minHeight: '30px'
                                    }}></div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody id='tableBody'>
                            {tableContent.value}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack alignItems="center" sx={{backgroundColor: "black"}}>
                    <Pagination
    //                                 rowsPerPageOptions={[-1]}
    //                                 rowsPerPage={10}
                                     component={'div'}
                                     count={Math.ceil(elementNumber.value / 10)}
                                     page={page.value}

                                     siblingCount={Math.floor(windowDimensions.width / 500)} boundaryCount={windowDimensions.width < 750 ? 0 : 1}
                                     sx={{backgroundColor: "black", color: "white", paddingTop:1,paddingBottom:1,
                                        button:{ color:'white',"&:hover": {backgroundColor:'rgba(3, 168, 192, 0.3)'},
                                            "&.Mui-selected": {pointerEvents: 'none', backgroundColor:'rgba(3, 168, 192, 0.5)'},
                                            "&.MuiPaginationItem-previousNext": {color:'#03a8c0;', fontWeight:'bold'},
                                            "&.MuiPaginationItem-firstLast": {color:'#03a8c0;', fontWeight:'bold'},
                                            },
                                            div:{color:'white', "&.MuiPaginationItem-ellipsis": {display: windowDimensions.width < 750 ? 'none' : ''}}
                                        }}
                                     renderItem={(item) => (
                                         <PaginationItem
                                           components={{
                                             last: (props) => <KeyboardDoubleArrowRight {...props} onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleChangePageCustom(page.value + 10)}}/>,
                                             first: (props) => <KeyboardDoubleArrowLeft {...props} onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleChangePageCustom(page.value - 10)}}/>,
                                           }}
                                           {...item}
                                         />
                                       )}
                                     showFirstButton
                                     showLastButton
                                     onChange={(event, page) => handleChangePage(event, page)}
                                     />
                </Stack>
              </Paper>

        </>
    );

}

export default TargetList;
